import React, { ReactNode, useState, useEffect } from 'react'
import { PortfolioType } from '../../../model/Portfolio';

type Props = {
  children: ReactNode;
  left: number;
  top?: number;
  bottom?: number;
  height: number;
  width: number;
  maxZoomRate?: number;
  firstViewFlag: boolean;
  setFirstViewFlag: React.Dispatch<React.SetStateAction<boolean>>;
  opacityRate: number;
  currentPortfolioType: PortfolioType;
  showPopupBase: boolean;
  setShowPopupBase: React.Dispatch<React.SetStateAction<boolean>>;
  portfolioType: PortfolioType;
  setPortfolioType: React.Dispatch<React.SetStateAction<PortfolioType>>;
}

const ObjectBase: React.FC<Props> = ({ children, left, top, bottom, height, width, maxZoomRate = 1.1, firstViewFlag, setFirstViewFlag, opacityRate, currentPortfolioType, showPopupBase, setShowPopupBase, portfolioType, setPortfolioType }) => {
  const [zoomRate, setZoomRate] = useState(firstViewFlag ? maxZoomRate : 1);
  const [mouseEnter, setMouseEnter] = useState(false);

  useEffect(() => {
    if (mouseEnter) {
      setFirstViewFlag(false)
    }
  }, [mouseEnter])

  useEffect(() => {
    if (mouseEnter || (showPopupBase && currentPortfolioType === portfolioType)) {
      setTimeout(() => {
        if (zoomRate < maxZoomRate) {
          setZoomRate(zoomRate + (maxZoomRate - 1) * 0.1);
        } else {
          setZoomRate(maxZoomRate)
        }
      }, 1000 / 60);
    } else {
      setTimeout(() => {
        if (zoomRate > 1) {
          setZoomRate(zoomRate - (maxZoomRate - 1) * (firstViewFlag ? 0.025 : 0.1));
        } else {
          setZoomRate(1)
        }
      }, 1000 / 60);
    }
  }, [mouseEnter, showPopupBase, currentPortfolioType, zoomRate])

  return (
    <div>
      <div
        style={{ position: "absolute", opacity: opacityRate, left: left - (zoomRate * maxZoomRate) * width / 2, top: top === undefined ? top : height / 2 + top - (zoomRate * maxZoomRate) * height / 2, bottom: bottom, width: "auto", height: (zoomRate * maxZoomRate) * height, cursor: "none" }}
        onClick={() => { setShowPopupBase(true); setPortfolioType(portfolioType); }}
        onMouseEnter={() => { setMouseEnter(true) }}
        onMouseLeave={() => { setMouseEnter(false) }}
      >
        {children}
      </div>
    </div>
  )
}

export default ObjectBase