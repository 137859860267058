import React, { useState } from 'react'
import { extractFileName } from '../utils/string';

type Props = {
  url: string;
  src: string;
  width: number;
}

const LinkImg: React.FC<Props> = ({ url, src, width }) => {
  const [mouseHover, setMouseHover] = useState(false);
  return (
    <a
      href={url}
      target="_blank" rel="noopener noreferrer"
      style={{ cursor: "none" }}
      onMouseEnter={() => setMouseHover(true)}
      onMouseLeave={() => setMouseHover(false)}
    >
      <img
        src={src}
        width={width}
        style={{opacity: mouseHover ? 0.6 : 1}}
        alt={extractFileName(src)}
      />
    </a>
  )
}

export default LinkImg