export type PortfolioDetail = {
  about: string;
  title: string;
  year: string;
  imgPaths: string[];
  headline: string;
}

export type PortfolioType =
  | ""
  | "about me"
  | "mirror"
  | "async studio"
  | "necotto"
  | "vok"
  | "dish"
  | "playlist"
  | "invitation"
  | "leaflet"
  | "cassette tape"
  | "flyer in bookshelf"
  | "flyer on wall"
  | "poster"
  | "pendentif"
  | "photo on wall";

export type PortfolioTitle =
  | ""
  | "aboutme"
  | "anjumori"
  | "asyncstudio"
  | "necotto"
  | "vok"
  | "kutanisankai"
  | "playlist"
  | "kinzangama"
  | "creaimo"
  | "keitamiyano"
  | "wallpaper"
  | "amagasaki"
  | "3pmmm"
  | "52hz"
  | "chapters";

export const portfolioMapping: { [key in PortfolioTitle]: PortfolioType } = {
  "": "",
  "aboutme": "about me",
  "anjumori": "mirror",
  "asyncstudio": "async studio",
  "necotto": "necotto",
  "vok": "vok",
  "kutanisankai": "dish",
  "playlist": "playlist",
  "kinzangama": "invitation",
  "creaimo": "leaflet",
  "keitamiyano": "cassette tape",
  "wallpaper": "flyer in bookshelf",
  "amagasaki": "flyer on wall",
  "3pmmm": "poster",
  "52hz": "pendentif",
  "chapters": "photo on wall",
};

export const portfolioMappingRev: { [key in PortfolioType]: PortfolioTitle } = {
  "": "",
  "about me": "aboutme",
  "mirror": "anjumori",
  "async studio": "asyncstudio",
  "necotto": "necotto",
  "vok": "vok",
  "dish": "kutanisankai",
  "playlist": "playlist",
  "invitation": "kinzangama",
  "leaflet": "creaimo",
  "cassette tape": "keitamiyano",
  "flyer in bookshelf": "wallpaper",
  "flyer on wall": "amagasaki",
  "poster": "3pmmm",
  "pendentif": "52hz",
  "photo on wall": "chapters",
};