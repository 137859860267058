import { ReactNode } from 'react'
import { PortfolioDetail, PortfolioType } from "../model/Portfolio";
import PopupMirror from '../components/Portfolios/Popups/PopupMirror';
import PopupAsyncStudio from '../components/Portfolios/Popups/PopupAsyncStudio';
import PopupCassetteTape from '../components/Portfolios/Popups/PopupCassetteTape';
import PopupDish from '../components/Portfolios/Popups/PopupDish';
import PopupFlyerInBookshelf from '../components/Portfolios/Popups/PopupFlyerInBookshelf';
import PopupFlyerOnWall from '../components/Portfolios/Popups/PopupFlyerOnWall';
import PopupInvitation from '../components/Portfolios/Popups/PopupInvitation';
import PopupLeaflet from '../components/Portfolios/Popups/PopupLeaflet';
import PopupNecotto from '../components/Portfolios/Popups/PopupNecotto';
import PopupPendentif from '../components/Portfolios/Popups/PopupPendentif';
import PopupPhotoOnWall from '../components/Portfolios/Popups/PopupPhotoOnWall';
import PopupPlaylist from '../components/Portfolios/Popups/PopupPlaylist';
import PopupPoster from '../components/Portfolios/Popups/PopupPoster';
import PopupVok from '../components/Portfolios/Popups/PopupVok';
import PopupAboutMe from '../components/Portfolios/Popups/PopupAboutMe';
import { offlineGetFirebaseFileURL } from '../firebase';

export const portfoliosOrderRoomView: PortfolioType[] = [
  "mirror",
  "async studio",
  "necotto",
  "vok",
  "about me",
  "dish",
  "playlist",
  "invitation",
  "leaflet",
  "cassette tape",
  "flyer in bookshelf",
  "flyer on wall",
  "poster",
  "pendentif",
  "photo on wall",
];

export const portfoliosOrderListView: PortfolioType[] = [
  "mirror",
  "async studio",
  "cassette tape",
  "dish",
  "invitation",
  "playlist",
  "poster",
  "necotto",
  "leaflet",
  "pendentif",
  "photo on wall",
  "vok",
  "flyer on wall",
  "flyer in bookshelf",
  "about me",
];

export const portfolioDetails: { [key in PortfolioType]: PortfolioDetail } = {
  "": {
    title: "",
    about: "",
    year: "",
    imgPaths: ["popup/empty.png"].map(filePath => offlineGetFirebaseFileURL(filePath)),
    headline: "",
  },
  "about me": {
    title: "About & Contanct",
    about: "",
    year: "",
    imgPaths: ["popup/aboutme/About_Contact.jpg"].map(filePath => offlineGetFirebaseFileURL(filePath)),
    headline: "",
  },
  "mirror": {
    title: "anju mori",
    about: "Corporate logo",
    year: "2024",
    imgPaths: ["popup/anjumori/anjumori_1.jpg", "popup/anjumori/anjumori_2.jpg", "popup/anjumori/anjumori_3.jpg",].map(filePath => offlineGetFirebaseFileURL(filePath)),
    headline: "はじまりをみる。\nありたい姿をかたちにする。",
  },
  "cassette tape": {
    title: "Keita Miyano",
    about: "Logo, Album artwork,  Contact card",
    year: "2022, 2023",
    imgPaths: ["popup/keitamiyano/keitamiyano_1.jpg", "popup/keitamiyano/keitamiyano_2.jpg", "popup/keitamiyano/keitamiyano_3.jpg", "popup/keitamiyano/keitamiyano_4.jpg", "popup/keitamiyano/keitamiyano_5.jpg", "popup/keitamiyano/keitamiyano_6.jpg"].map(filePath => offlineGetFirebaseFileURL(filePath)),
    headline: "デジタルとアナログの交差点",
  },
  "playlist": {
    title: "ひと月の生活をいとおしむ<wbr>プレイリスト",
    about: "Cover artwork",
    year: "2023",
    imgPaths: ["popup/playlist/03_playlist_1.jpg", "popup/playlist/03_playlist_2.jpg", "popup/playlist/03_playlist_3.jpg", "popup/playlist/03_playlist_4.jpg", "popup/playlist/03_playlist_5.jpg"].map(filePath => offlineGetFirebaseFileURL(filePath)),
    headline: "ひと月の生活に想いを馳せる。",
  },
  "leaflet": {
    title: "Creaimo",
    about: "Leaflet",
    year: "2023",
    imgPaths: ["popup/Creaimo/Creaimo_1.jpg"].map(filePath => offlineGetFirebaseFileURL(filePath)),
    headline: "Creaimoの店舗に訪れたくなる\n手のひらサイズのリーフレット",
  },
  "invitation": {
    title: "錦山窯 特別受注会",
    about: "Invitation, Key visual",
    year: "2022",
    imgPaths: ["popup/kinzangama/kinzangama01.jpg", "popup/kinzangama/kinzangama02.jpg", "popup/kinzangama/kinzangama03.jpg"].map(filePath => offlineGetFirebaseFileURL(filePath)),
    headline: "連なり、広がる、\n洗練された高揚感。",
  },
  "flyer on wall": {
    title: "尼崎の森間学校",
    about: "Flyer",
    year: "2023",
    imgPaths: ["popup/amagasaki/amagasaki01.jpg", "popup/amagasaki/amagasaki02.jpg", "popup/amagasaki/amagasaki03.jpg", "popup/amagasaki/amagasaki04.jpg",].map(filePath => offlineGetFirebaseFileURL(filePath)),
    headline: "「なにか」を発見し\n\u2002自由気ままに森をかけ回る",
  },
  "poster": {
    title: "3PMmm...",
    about: "Poster booklet",
    year: "2022, 2023",
    imgPaths: ["popup/3PMmm/3PMmm_1.jpg", "popup/3PMmm/3PMmm_2.jpg", "popup/3PMmm/3PMmm_3.jpg", "popup/3PMmm/3PMmm_4.jpg", "popup/3PMmm/3PMmm_5.jpg"].map(filePath => offlineGetFirebaseFileURL(filePath)),
    headline: "よりよいおやつ時間に寄り添う",
  },
  "dish": {
    title: "九谷餐会",
    about: "Logo, Event visual, Menu",
    year: "2021",
    imgPaths: ["popup/kutanisankai/kutanisankai_1.jpg", "popup/kutanisankai/kutanisankai_2.jpg", "popup/kutanisankai/kutanisankai_3.jpg", "popup/kutanisankai/kutanisankai_4.jpg", "popup/kutanisankai/kutanisankai_5.jpg", "popup/kutanisankai/kutanisankai_6.jpg"].map(filePath => offlineGetFirebaseFileURL(filePath)),
    headline: "伝統と現代を行き来する",
  },
  "pendentif": {
    title: "52Hz.",
    about: "Brand logo, Accessory card, Poster, Postcard",
    year: "2021-2023",
    imgPaths: ["popup/52Hz/52Hz_3.jpg", "popup/52Hz/52Hz_1.jpg", "popup/52Hz/52Hz_2.jpg", "popup/52Hz/52Hz_4.jpg", "popup/52Hz/52Hz_5.jpg", "popup/52Hz/52Hz_6.jpg"].map(filePath => offlineGetFirebaseFileURL(filePath)),
    headline: "泡沫のきらめき、ゆらめく儚さ",
  },
  "photo on wall": {
    title: "Chapters書店",
    about: "Photo, Handwriting",
    year: "2022",
    imgPaths: ["popup/chapters/10_chapters_1.jpg", "popup/chapters/10_chapters_2.jpg", "popup/chapters/10_chapters_3.jpg", "popup/chapters/10_chapters_4.jpg", "popup/chapters/10_chapters_5.jpg", "popup/chapters/10_chapters_6.jpg",].map(filePath => offlineGetFirebaseFileURL(filePath)),
    headline: "４つの小説の「桜」を解釈し、表現する。",
  },
  "vok": {
    title: "知の航海者たち",
    about: "Project logo, Webinar poster, Featured image",
    year: "2023, 2024",
    imgPaths: ["popup/VoK/VoK01.jpg", "popup/VoK/VoK02.jpg", "popup/VoK/VoK03.jpg", "popup/VoK/VoK04.jpg",].map(filePath => offlineGetFirebaseFileURL(filePath)),
    headline: "知のしなやかさと寛容さ",
  },
  "flyer in bookshelf": {
    title: "Wallpaper",
    about: "Photo, Writing, Handwriting",
    year: "2021",
    imgPaths: ["popup/tsukanomano/popup_0323_tsukanomano01.jpg", "popup/tsukanomano/popup_0323_tsukanomano02.jpg", "popup/tsukanomano/popup_0323_tsukanomano03.jpg"].map(filePath => offlineGetFirebaseFileURL(filePath)),
    headline: "束の間の深呼吸",
  },
  "necotto": {
    title: "necotto",
    about: "Corporate logo",
    year: "2021",
    imgPaths: ["popup/necotto/necotto_1.jpg", "popup/necotto/necotto_2.jpg", "popup/necotto/necotto_3.jpg", "popup/necotto/necotto_4.jpg", "popup/necotto/necotto_5.jpg", "popup/necotto/necotto_6.jpg", "popup/necotto/necotto_7.jpg"].map(filePath => offlineGetFirebaseFileURL(filePath)),
    headline: "猫の愛らしさとぬくもり",
  },
  "async studio": {
    title: "async studio",
    about: "Corporate logo",
    year: "2023",
    imgPaths: ["popup/asyncstudio/popup_0323_asyncstudio01.jpg", "popup/asyncstudio/popup_0323_asyncstudio02.jpg", "popup/asyncstudio/popup_0323_asyncstudio03.jpg", "popup/asyncstudio/popup_0323_asyncstudio04.jpg", "popup/asyncstudio/popup_0323_asyncstudio05.jpg"].map(filePath => offlineGetFirebaseFileURL(filePath)),
    headline: "async studio から始まる、\nゆるやかな非同期",
  }
}

export const portfolioPopup: { [key in PortfolioType]: ((fontResizeRate: number) => ReactNode) } = {
  "": () => <></>,
  "about me": (fontResizeRate) => <PopupAboutMe fontResizeRate={fontResizeRate} />,
  "mirror": (fontResizeRate) => <PopupMirror fontResizeRate={fontResizeRate} />,
  "cassette tape": (fontResizeRate) => <PopupCassetteTape fontResizeRate={fontResizeRate} />,
  "playlist": (fontResizeRate) => <PopupPlaylist fontResizeRate={fontResizeRate} />,
  "leaflet": (fontResizeRate) => <PopupLeaflet fontResizeRate={fontResizeRate} />,
  "invitation": (fontResizeRate) => <PopupInvitation fontResizeRate={fontResizeRate} />,
  "flyer on wall": (fontResizeRate) => <PopupFlyerOnWall fontResizeRate={fontResizeRate} />,
  "poster": (fontResizeRate) => <PopupPoster fontResizeRate={fontResizeRate} />,
  "dish": (fontResizeRate) => <PopupDish fontResizeRate={fontResizeRate} />,
  "pendentif": (fontResizeRate) => <PopupPendentif fontResizeRate={fontResizeRate} />,
  "photo on wall": (fontResizeRate) => <PopupPhotoOnWall fontResizeRate={fontResizeRate} />,
  "vok": (fontResizeRate) => <PopupVok fontResizeRate={fontResizeRate} />,
  "flyer in bookshelf": (fontResizeRate) => <PopupFlyerInBookshelf fontResizeRate={fontResizeRate} />,
  "necotto": (fontResizeRate) => <PopupNecotto fontResizeRate={fontResizeRate} />,
  "async studio": (fontResizeRate) => <PopupAsyncStudio fontResizeRate={fontResizeRate} />
}
