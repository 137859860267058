import React from 'react'
import { PopupBody, PopupBodySubHeadline } from './PopupBase'
import LinkText from '../../LinkText'

type Props = {
  fontResizeRate: number;
}

const PopupPlaylist: React.FC<Props> = ({ fontResizeRate }) => {
  return (
    <div>
      <PopupBody fontResizeRate={fontResizeRate}>
        「ことば」から紡ぐ、「ひと月の生活をいとおしむプレイリスト」のアートワークの制作。kotaくんとの会話で生まれた「○○なシーンに合う曲ってプレイリストは多いけれど、なにかもう少し別の軸でつくれないだろうか」との視点。そこから、このプレイリストたちを毎月リリースする試みが生まれました。
      </PopupBody>

      <PopupBodySubHeadline fontResizeRate={fontResizeRate}>
        プレイリストが生まれるまで
      </PopupBodySubHeadline>

      <PopupBody fontResizeRate={fontResizeRate}>
        まず、2人でひと月から想起される「ことば」を考えます。「このひと月ってこんな雰囲気があるよね」「もう少し明るい方がこの月らしいかも」など、語感・温度感・イメージの擦り合わせをしてテーマとなる「ことば」を決めます。
        <br /><br />
        次に「ことば」を元に各々の作業に入ります。曲のキュレーションと写真、プレイリストの紹介文を担当するkotaくんが1曲1曲を集めてプレイリストを組み、kotaくん自身の撮影した写真の中からイメージに合いそうなものをピックアップします。（6月のしたたるは、森が写真を担当しています）自分は、まずいただいた写真たちを組み合わせます。組み合わせを2人で検証し、もっともしっくりくる1枚に仕上げてから、「ことば」の手書き文字・ペイントを加えてアートワークをつくります。
        <br /><br />
        kotaくんのプレイリストを聴きながらアートワークをつくる。森の作ったアートワークから、プレイリストの内容や紹介文を編集する。お互いの作業をいったりきたりしながら、イメージを深め1つのプレイリストへ仕上げていきます。
      </PopupBody>

      <PopupBodySubHeadline fontResizeRate={fontResizeRate}>
        「ことば」からアートワークへ
      </PopupBodySubHeadline>

      <PopupBody fontResizeRate={fontResizeRate}>
        アートワークを作る際には「ことば」から連想される質感からデザインを膨らませています。例えば「めぐる」であれば、ぐるぐると螺旋状に巡っていく様子。「ほどける」であれば、きゅっと固くなっていたものがゆるむニュアンス。そこにkotaくんのお写真にある色合いや雰囲気、2人で相談したときのイメージを重ね合わせながら、文字を綴りペイントを描いています。
        <br /><br />
        少し脱線しているものもあり、例えば「ぬくむ」は言葉のニュアンスに加え、2月22日に猫の日があり2人とも猫好きなところから、かなりねこねこな仕上がりに。
        <br /><br />
        kotaくんの素晴らしい選曲はもちろん、アートワークのお写真の組み合わせや文字、ペイントたちも楽しみながら聞いていただけたら嬉しいです。
        <br /><br />
        URL：
        <LinkText url="https://linktr.ee/kotoba_playlist" />
        <br />
        Curation & Photograph：Kota Ogasawara
        <br />
        Artwork：Anju Mori
      </PopupBody>



    </div>
  )
}

export default PopupPlaylist