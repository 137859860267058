import React from 'react'
import { PopupBody, PopupBodySubHeadline } from './PopupBase'

type Props = {
  fontResizeRate: number;
}

const PopupNecotto: React.FC<Props> = ({ fontResizeRate }) => {
  return (
    <div>

      <PopupBody fontResizeRate={fontResizeRate}>
        「猫と暮らす幸せ」がコンセプトの複合施設「necotto」と、含まれる3施設「necotto cafe」「k.necotto」「necotto labo.」のロゴを制作しました。
        <br /><br />
        「necotto」は猫と共に育む施設であるため、necoが窓辺に佇む様子でストレートに表現。ヒアリングから伝わってきたこだわりとぬくもりを、クラフト感のある木版画のようなタッチに込めました。カクカクした質感で、ふくふくとした猫の愛らしいかたちを表現しています。
        <br /><br />
        各施設のロゴは「necotto」のロゴにいる「neco」とそれぞれのスペースの象徴的なモチーフで成り立っています。カフェスペースのnecotto cafeは、猫が目で追ってしまうような動きをしているコーヒー豆と茶葉。住居スペースのk.necottoは、necottoの実際のかたちを元にしたアーチが特徴のお家。レンタルスペースのnecotto labo.は、充実したキッチンが特徴であることを示す両手鍋。施設全体の名前「necotto」の由来である「猫と一緒」を、neco＋モチーフで明確に表現しました。necottoのロゴたちは並べて使用すると、1匹また1匹と猫が集うように見え、かわいらしく親しみやすい印象が増していきます。
        <br /><br />
        Client：ライフサポート株式会社
        <br />
        Planning & Architectural design：株式会社チームネット
        <br />
        Direction：株式会社neconote
      </PopupBody>

    </div>
  )
}

export default PopupNecotto