import React from 'react'
import { PopupBody, PopupBodySubHeadline } from './PopupBase'

type Props = {
  fontResizeRate: number;
}

const PopupFlyerInBookshelf: React.FC<Props> = ({ fontResizeRate }) => {
  return (
    <div>
      <PopupBody fontResizeRate={fontResizeRate}>
        iPhoneやガジェットの「べんり！」をお届けするマメさんのYoutubeメンバーシップ限定の壁紙を制作しました。新生活とまでは言わなくても、何かと変化の多い春。配布月の5月は「五月病」という言葉があるほどにつかれが出やすい時期であることから、束の間の深呼吸をコンセプトに撮影、文章と手書き文字の制作を行いました。
        <br /><br />
        写真にあるのは、レモンを浮かべた炭酸水とぶどう。物凄く手の込んだ何かでなくても、忙しない日々の一瞬に、きちんと心落ち着かせる瞬間がつくれますようにと願いを込めました。手書き文字は余白感をたっぷりにゆっくりと深呼吸をしているイメージ。つかれの溜まりやすい季節に、「ちょっと休憩しようかな」と思える1枚を目指しています。
      </PopupBody>
    </div>
  )
}

export default PopupFlyerInBookshelf
