import React from 'react'
import PopupBase, { ModalMove } from './PopupBase'
import { PortfolioType } from '../../../model/Portfolio'
import { portfolioDetails, portfolioPopup } from '../../../consts/PortfolioDetails'
import { XY } from '../../../model/XY'

type PopupProps = {
  portfolioType: PortfolioType,
  modalMove: ModalMove,
  showPopupBase: boolean,
  setShowPopupBase: React.Dispatch<React.SetStateAction<boolean>>,
  headerHeight: number,
  setMouseXY: React.Dispatch<React.SetStateAction<XY>>;
}

const Popup: React.FC<PopupProps> = ({ portfolioType, modalMove, showPopupBase, setShowPopupBase, headerHeight, setMouseXY }) => {
  return (
    <PopupBase
      modalMove={modalMove}
      portfolioDetail={portfolioDetails[portfolioType]}
      showPopupBase={showPopupBase}
      setShowPopupBase={setShowPopupBase}
      headerHeight={headerHeight}
      setMouseXY={setMouseXY}
      displayDesignConcept={portfolioType!=="about me"}
    >
      {portfolioPopup[portfolioType]}
    </PopupBase>
  )
}

export default Popup