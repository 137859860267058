import React, { useState, useEffect } from 'react'

type Props = {
  width: number;
  fill: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

const Next: React.FC<Props> = ({ width, fill, onClick }) => {
  const height = width / (16.176624 / 32.353249)
  const [mouseHover, setMouseHover] = useState(false);
  const [opacity, setOpacity] = useState(1)

  useEffect(() => {
    setOpacity(mouseHover ? 0.7 : 1)
  }, [mouseHover])

  return (
    <div style={{ position: "fixed", right: 0, top: `calc( 50% - ${height * 0.5}px )`, height: height, width: "auto", cursor: "none" }}
      onClick={onClick}
      onMouseEnter={() => setMouseHover(true)}
      onMouseLeave={() => setMouseHover(false)}
    >
      <svg
        style={{ position: "relative", height: "100%" }}
        viewBox="0 0 16.176624 32.353249"
        version="1.1"
        id="svg1"
        xmlns="http://www.w3.org/2000/svg">
        <defs
          id="defs1" />
        <g
          id="layer1"
          transform="translate(-141.2875,-133.08542)">
          <g
            id="g260"
            transform="matrix(0.26458333,0,0,0.26458333,-181.20254,13.795377)">&#10;		<path
              className="st32"
              d="m 1280,572.14 c -33.22,0 -60.14,-26.93 -60.14,-60.14 0,-33.21 26.93,-60.14 60.14,-60.14"
              id="path259"
              style={{ fill: fill, fillOpacity: 0.8 * opacity, stroke: `rgba(34,34,34,${opacity})`, strokeWidth: 2, strokeMiterlimit: 10 }} />
            &#10;		<polygon
              className="st6"
              points="1254.95,503.5 1251.86,503.5 1260.36,512 1251.86,520.5 1254.95,520.5 1263.45,512 "
              id="polygon259"
              style={{ fill: `rgba(34,34,34,${opacity})` }} />
            &#10;	</g>
        </g>
      </svg>
    </div>
  )
}

export default Next