import React from 'react'

type Props = {
  padding: string | number;
  backgroundColor: string;
  handleClose: () => void;
}

const CloseButton: React.FC<Props> = ({ padding, backgroundColor, handleClose }) => {
  return (
    <svg
      style={{ position: "relative", height: "100%", padding: padding, cursor: "none", backgroundColor: backgroundColor }}
      onClick={handleClose}
      viewBox="0 0 127.70555 127.70541"
      version="1.1"
      id="svg1"
      xmlns="http://www.w3.org/2000/svg">
      <defs
        id="defs1" />
      <g
        id="layer1"
        transform="translate(-41.51109,-75.79714)">
        <g
          id="g1601"
          transform="matrix(10.191716,0,0,10.191716,-557.50535,-2490.9339)">&#10;				<g
            id="g1599">&#10;					<line
              className="st62"
              x1="71.040001"
              y1="252.11"
              x2="59.040001"
              y2="264.10999"
              id="line1599"
              style={{ fill: "none", stroke: "#000000", strokeWidth: 0.75, strokeMiterlimit: 10 }} />
            &#10;				</g>
          &#10;				<g
            id="g1600">&#10;					<line
              className="st62"
              x1="71.040001"
              y1="264.10999"
              x2="59.040001"
              y2="252.11"
              id="line1600"
              style={{ fill: "none", stroke: "#222", strokeWidth: 0.75, strokeMiterlimit: 10 }} />
            &#10;				</g>
          &#10;			</g>
      </g>
    </svg>
  )
}

export default CloseButton