import React from 'react'
import { PopupBody, PopupBodySubHeadline } from './PopupBase'

type Props = {
  fontResizeRate: number;
}

const PopupDish: React.FC<Props> = ({ fontResizeRate }) => {
  return (
    <div>
      <PopupBody fontResizeRate={fontResizeRate}>
        九谷焼と食のペアリング企画「九谷餐会」のロゴ、イベントビジュアル、メニュー表を担当しました。高級旅館に飾られている物、割烹料理店の器など敷居の高いイメージのある九谷焼をもっと身近に感じてほしい。企画の根っこにある想いを元に、九谷焼の伝統・洗練された雰囲気と現代的な新しさの両方が伝わるデザインを目指しました。
      </PopupBody>

      <PopupBodySubHeadline fontResizeRate={fontResizeRate}>
        伝統ある重厚さと幾何学モダン
      </PopupBodySubHeadline>

      <PopupBody fontResizeRate={fontResizeRate}>
        「つくるのは九谷のあるくらしの実体験」。伝統を感じる重厚さとモダンで幾何学的なニュアンスを組み合わせ、伝統ある九谷焼のあたらしい在り方を模索する、九谷餐会の姿勢を表現したロゴマークです。横線は繊細に縦線はどっしり太く。メリハリのある縦横のラインに、蒔絵を描く筆の軌跡や器を示す丸のやわらかさ。「谷」は、会場の美しい佇まいから生まれたかたちです。洗練された高級感のある雰囲気に、これまでの九谷焼より1歩近い親しみやすさが伝わるデザインを目指しています。
      </PopupBody>

      <PopupBodySubHeadline fontResizeRate={fontResizeRate}>
        2色のイベントビジュアル
      </PopupBodySubHeadline>

      <PopupBody fontResizeRate={fontResizeRate}>
        九谷餐会を告知するイベントビジュアル。キーカラーは九谷焼を代表する技法のひとつ「金彩」を想起させる、落ち着きのある琥珀色。琥珀色と対のイメージで、九谷焼の色彩の豊かさを品よく魅せてくれる錫色を採用しています。2色のキーカラーを交互に使用することで、イベントのお知らせが毎月ある中でも新鮮さを損なわず、「前回開催の時と何かが違うのかな」と興味をもっていただく狙いがあります。
      </PopupBody>

      <PopupBodySubHeadline fontResizeRate={fontResizeRate}>
        九谷焼を味わうためのメニュー表
      </PopupBodySubHeadline>

      <PopupBody fontResizeRate={fontResizeRate}>
        食材からではなく、九谷焼からメニューを発想する。洗練された空間でいただく、九谷餐会だけの特別なお食事にふさわしいメニュー表を目指しています。毎回変わるゲストシェフによるテーマを主役にキーカラーの琥珀色をあしらい、品のある装いです。片面には九谷餐会のコピー「つくるのは九谷のあるくらしの実体験」の文章を綴り、イベント根幹にある想いを伝える読み物としての役割を添えました。
      </PopupBody>
    </div>
  )
}

export default PopupDish
