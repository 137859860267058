export const hasJapanese = (text: string) => {
  const regex = /[\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FAF\u3000-\u303F]/;
  return regex.test(text);
}

export const extractFileName = (path: string): string => {
  const parts = path.split("/").filter(part => part.trim() !== "");
  if (parts.length === 0) return "";

  const lastPart = parts[parts.length - 1];
  const fileNameParts = lastPart.split(".");
  if (fileNameParts.length < 2) return lastPart;

  return fileNameParts.slice(0, -1).join(".");
}

export const spImgPath = (path: string, verticalFlag: boolean): string => {
  if (verticalFlag) {
    return path.replace(/(.*)\./, "$1_sp.")
  } else {
    return path
  }
}