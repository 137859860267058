import React from 'react'
import { PopupBody, PopupBodySubHeadline } from './PopupBase'

type Props = {
  fontResizeRate: number;
}

const PopupMirror: React.FC<Props> = ({ fontResizeRate }) => {
  return (
    <div>
      <PopupBody fontResizeRate={fontResizeRate}>
        大切にしたいこと、どうありたいのか。自分自身と対話を重ねて制作したロゴマークです。
      </PopupBody>

      <PopupBodySubHeadline fontResizeRate={fontResizeRate}>
        「am」が持つ意味
      </PopupBodySubHeadline>

      <PopupBody fontResizeRate={fontResizeRate}>
        ロゴを作るための1歩として自分を構成する要素の洗い出しと、姿勢や大切にしたい感覚の言語化を行いました。その過程でanju moriのイニシャルであり世間一般で使われている「am」の意味が、自身の芯と重なる部分が多いことに気がつきました。
        <br /><br />
        まずa.m.は午前中、1日の前半を指します。1日を物事として捉えると、a.m.は、はじまり。物事のはじまりとなる想い、根幹や本質。対話を重ね、はじまりをみつめることを大切に思う自身の姿勢と重なるように思います。
        <br /><br />
        またamは「～である、～でいる」との意味がある、be動詞一人称単数形です。デザインの領域のみに限らず日々の活動において、やりたいことの元にある「どうありたいか」に思考を巡らせてきた姿勢と重なります。一人称単数形である部分もフィットしていて、「みんな」でなく「わたし（あなた、個々）」のありたい姿を示唆しているところが、とてもしっくりきます。
        <br /><br />
        amの発音「アム」から連想される「編む」も自身の芯に繋がることばです。さまざまな要素（糸）が相互に引き立て合うような1つのかたちに編むことを日々大切にしています。
        <br /><br />
        当初はロゴタイプ（文字部分）のみのロゴをイメージしていましたが、これらの気づきを踏まえて自身のイニシャル「am」をシンボルマークとして掲げることとしました。
      </PopupBody>

      <PopupBodySubHeadline fontResizeRate={fontResizeRate}>
        姿勢を表現するかたち
      </PopupBodySubHeadline>

      <PopupBody fontResizeRate={fontResizeRate}>
        anju moriのシンボルマークは活版印刷の普及とともに生まれた活字（フォント）「a」とガラスペンで手描きした「m」、生まれた背景の異なる2種類の文字を組み合わせています。1つの視点に捉われずに物事をみる姿勢、白と黒、0と100のような両極の「間」にあるものを大切にする心持ちを表現しました。
        <br /><br />
        ロゴタイプは活字7文字の中にひとつだけ手書き文字がある構成です。活字を「論理」手書き文字を「感覚」と捉えて、思い描くよい制作のかたち『論理・言語化がベースにある直感的な「なんか良い」』を表現しています。7/8の論理と1/8の感覚が混ざりあうような制作を重ねていきたいです。
        <br /><br />
        まず造形として魅力を感じる「かたち」。その軸として想いがあること。日々目指している「かたち」に仕上がりとても嬉しい気持ちです。つくって終わりではなく、ここから機能してはじまり。たくさん愛でて、たくさん使っていこうと思います。
      </PopupBody>
    </div>
  )
}

export default PopupMirror