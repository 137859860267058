import React from 'react'

const VerticalDivider = () => {
  return (
    <svg
      style={{ position: "relative", height: "100%" }}
      viewBox="0 0 0.79374999 218.01666"
      version="1.1"
      id="svg1"
      xmlns="http://www.w3.org/2000/svg">
      <defs
        id="defs1" />
      <g
        id="layer1"
        transform="translate(-104.51041,-39.422916)">
        <line
          className="st26"
          x1="104.90729"
          y1="39.422916"
          x2="104.90729"
          y2="257.43958"
          id="line199"
          style={{fill:"none",stroke:"#231815",strokeWidth:0.79375,strokeMiterlimit:10,strokeDasharray:"0.132292, 1.05833"}} />
      </g>
    </svg>
  )
}

export default VerticalDivider