export const map = (value: number, fromMin: number, fromMax: number, toMin: number, toMax: number) => {
  let ratio = (toMax - toMin) / (fromMax - fromMin);
  return (value - fromMin) * ratio + toMin;
};

export function convertToRange(value: number, min: number, max: number) {
  let result = ((value - min) % (max - min)) + min
  // jsは割られる数が負の場合余りも負になってしまう
  if ((value - min)<0) {
    result += (max - min)
  }
  return result
}