import { PortfolioType } from "../model/Portfolio";

export type CurrentElements = {
  previousElement: PortfolioType,
  currentElement: PortfolioType,
  nextElement: PortfolioType,
}

export const getPrevNextElementsFromArray = (array: PortfolioType[], element: PortfolioType): CurrentElements => {
  const index = array.indexOf(element);
  if (index === -1) {
    return {
      previousElement: "",
      currentElement: "",
      nextElement: "",
    };
  }

  const length = array.length;
  const previousIndex = (index - 1 + length) % length;
  const nextIndex = (index + 1) % length;

  const previousElement = array[previousIndex];
  const currentElement = element;
  const nextElement = array[nextIndex];

  return { previousElement, currentElement, nextElement };
}