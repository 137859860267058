import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import { Arrow } from '../../Arrow';
import { useWindowDimensions } from '../../../utils/windowDimensions'
import { map } from '../../../utils/math';
import { isSmartPhone } from '../../../utils/computerTerminal';
import { PortfolioDetail } from '../../../model/Portfolio';
import { PortfolioType } from '../../../model/Portfolio';
import { extractFileName, hasJapanese, spImgPath } from '../../../utils/string';

type Props = {
  portfolioDetail: PortfolioDetail;
  setShowPopupBase: React.Dispatch<React.SetStateAction<boolean>>;
  portfolioType: PortfolioType;
  setPortfolioType: React.Dispatch<React.SetStateAction<PortfolioType>>;
}

const ListBase: React.FC<Props> = ({ portfolioDetail, setShowPopupBase, portfolioType, setPortfolioType }) => {
  const { width, verticalFlag } = useWindowDimensions()
  const isTouchDevice = isSmartPhone();
  const defaultListWidth = 510
  const listWidth = Math.min(defaultListWidth, width - 45)
  const [showPopupHint, setShowPopupHint] = useState(false)
  const [popupHintRate, setPopupHintRate] = useState(0);
  const removeWbr = (title: string) => {
    return title.replace("<wbr>", "")
  }
  const [mouseHoverOnHint, setMouseHoverOnHint] = useState(false)
  const [hintOpacity, setHintOpacity] = useState(1)

  useEffect(() => {
    if (showPopupHint) {
      setTimeout(() => {
        if (popupHintRate < 1) {
          setPopupHintRate(popupHintRate + Math.min(0.1, 1 - popupHintRate));
        } else {
          setPopupHintRate(1)
        }
      }, 1000 / 60);
    } else {
      setTimeout(() => {
        if (popupHintRate > 0) {
          setPopupHintRate(popupHintRate - Math.min(0.1, popupHintRate));
        } else {
          setPopupHintRate(0)
        }
      }, 1000 / 60);
    }
  }, [showPopupHint, popupHintRate])

  useEffect(() => {
    setHintOpacity(mouseHoverOnHint ? 0.7 : 1)
  }, [mouseHoverOnHint])

  const marginWidth = 30 * listWidth / defaultListWidth
  const marginHeight = 30 * listWidth / defaultListWidth
  const borderWidth = 1.8 * (listWidth / defaultListWidth)**0.7

  return (
    <Grid item
      style={{
        position: "relative",
        width: listWidth,
        height: ((listWidth - borderWidth*2) * (2 / 3) + (borderWidth*2)),
        borderColor: "#222222",
        borderWidth: borderWidth,
        borderStyle: "solid",
        marginTop: marginHeight / 2,
        marginBottom: marginHeight / 2,
        marginLeft: marginWidth / 2,
        marginRight: marginWidth / 2,
        overflow: "hidden",
        cursor: "none",
      }}
      onMouseEnter={() => { if (!isTouchDevice && !showPopupHint) setShowPopupHint(true); }}
      onMouseLeave={() => { if (!isTouchDevice && showPopupHint) setShowPopupHint(false); }}
    >
      <div
        style={{
          position: "absolute",
          width: '100%',
          height: "100%",
          margin: '0 auto',
          textAlign: "center",
        }}
        onClick={() => { if (isTouchDevice) setShowPopupHint(!showPopupHint); }}
      >
        <img style={{ height: "100%", width: "100%" }} src={spImgPath(portfolioDetail.imgPaths[0], verticalFlag)} alt={extractFileName(portfolioDetail.imgPaths[0])}/>
      </div>
      <Grid
        container justifyContent="flex-start" alignItems="center" style={{
          position: "absolute", bottom: map(popupHintRate, 0, 1, -listWidth * 0.6 * 0.3737, 0), height: listWidth * 0.6 * 0.3737, width: "100%", backgroundColor: `rgb(233,231,220,${0.9 * hintOpacity})`, cursor: "none"
        }}
        onClick={() => { setShowPopupBase(true); setPortfolioType(portfolioType); }}
        onMouseEnter={() => { setMouseHoverOnHint(true) }}
        onMouseLeave={() => { setMouseHoverOnHint(false) }}
      >
        <Grid item style={{ opacity: hintOpacity, paddingTop: listWidth * 0.03, height: "100%", paddingBottom: listWidth * 0.03, paddingLeft: listWidth * 0.03, paddingRight: listWidth * 0.03, textAlign: "start", whiteSpace: "pre" }}>
          <div className="listhint-title" style={{
            fontSize: `${1.03 * 18 * (listWidth / defaultListWidth) * (hasJapanese(portfolioDetail.title) ? 0.9 : 1)}pt`,
            letterSpacing: `${0 * listWidth / defaultListWidth}pt`,
            lineHeight: `${28 * listWidth / defaultListWidth}px`,
          }}>
            {removeWbr(portfolioDetail.title)}
          </div>
          <div className="listhint-about" style={{
            fontSize: `${1.03 * 13 * listWidth / defaultListWidth}pt`,
            lineHeight: `${31 * listWidth / defaultListWidth}px`,
          }}>
            {portfolioDetail.about}
          </div>
          <div className="listhint-year" style={{
            fontSize: `${1.03 * 11 * listWidth / defaultListWidth}pt`,
            letterSpacing: `${0 * listWidth / defaultListWidth * listWidth / defaultListWidth}pt`,
            lineHeight: `${13 * listWidth / defaultListWidth}pt`,
          }}>
            {portfolioDetail.year}
          </div>
        </Grid>
        <Arrow
          right={listWidth * 0.035}
          bottom={listWidth * 0.025}
          height={listWidth * (0.6 * 0.3737 - 0.07)}
          opacity={hintOpacity}
        />
      </Grid>
    </Grid>
  )
}

export default ListBase