import React from 'react'

type Props = {
  right: number;
  bottom: number;
  height: number;
  opacity: number;
}


export const Arrow: React.FC<Props> = ({ right, bottom, height, opacity }) => {
  return (
    <svg
      style={{ position: "absolute", right: right, bottom: bottom, height: height, width: "auto" }}
      viewBox="0 0 27.574112 27.574112"
      version="1.1"
      id="svg3155"
      xmlns="http://www.w3.org/2000/svg">
      <defs
        id="defs3152" />
      <g
        id="layer1"
        transform="translate(-97.465093,-119.87754)">
        <g
          id="g132"
          transform="matrix(0.26458333,0,0,0.26458333,-54.663148,0.46843691)">
          <polyline
            className="st6"
            points="593.05,452.31 678.19,452.31 678.19,537.45     "
            id="polyline128"
            style={{ fill: "none", stroke: `rgba(34,34,34,${opacity})`, strokeWidth: 2, strokeMiterlimit: 10 }} />

          <line
            className="st6"
            x1="575.67999"
            y1="554.82001"
            x2="678.19"
            y2="452.31"
            id="line130"
            style={{ fill: "none", stroke: `rgba(34,34,34,${opacity})`, strokeWidth: 2, strokeMiterlimit: 10 }} />

        </g>
      </g>
    </svg>
  )
}
