import React from 'react'
import { PopupBody, PopupBodySubHeadline } from './PopupBase'

type Props = {
  fontResizeRate: number;
}

const PopupVok: React.FC<Props> = ({ fontResizeRate }) => {
  return (
    <div>
      <PopupBody fontResizeRate={fontResizeRate}>
        「だれでも、いつでも、どこでも、一流の学びを」を理念に掲げるウェビナー「知の航海者たち」のロゴマーク、ポスター、アイキャッチ各種を制作しました。
      </PopupBody>

      <PopupBodySubHeadline fontResizeRate={fontResizeRate}>
        知の航海を体現するロゴマーク
      </PopupBodySubHeadline>

      <PopupBody fontResizeRate={fontResizeRate}>
        ロゴタイプ（文字部分）は知のしなやかさに含まれる、柔軟さと確かな芯の強さから形作りました。起伏のある波線は、穏やかな波や大きな高波を進む「知」の航海に由来しています。ヒアリングより「知」はジャンルの違いはあれど、単独での存在はしておらずあらゆる箇所で繋がっているとの解釈から、文字の境界はありながらも波線の連なりを表現し、どんな波（知）にも繋がりがあることを示しています。<br /><br />
        シンボルマークは「知」の文字構成を軸に、いったりきたりと打ち寄せる波から形作りました。学びは常に一方向へ進むものではなく、時に立ち返ったりいったりきたりする中で育まれるものであることを示しています。本来横線がある部分を「余白」とし、知や学びがどなたにでも開かれていること、その寛容さを表現しました。
      </PopupBody>

      <PopupBodySubHeadline fontResizeRate={fontResizeRate}>
        知の航海者を募るアイキャッチたち
      </PopupBodySubHeadline>

      <PopupBody fontResizeRate={fontResizeRate}>
        ウェビナーを告知し、知の航海者たちの世界観が明確に伝わること。一流の学びが得られることが印象的に伝わり、見かけた方が参加したくなるようなポスターとアイキャッチを目指してデザインしました。<br /><br />
        知の航海者たちはご依頼いただいた後も講師とテーマ、内容を毎回変更しながら変えながら展開していきます。そのため制作したデザインは5種類全て、主催の「知の航海者たち」企画の皆さまがテキストを入れ替えて継続してお使いいただける仕様になっています。<br /><br />
        カラーバリエーションを2つ用意し、回ごとに変更する。背景に用いる写真を毎回変更する。ノンデザイナーの方でも作業しやすい汎用性の高いデザインを軸に、単に同じデザインには見せないようなベース作りを目指しました。<br /><br />
        毎回、約1000名の参加者が集まる知の航海者たち。自身が告知用のアイキャッチをデザインした回は、その前の回と比べ約160名を上回る集客があったとご報告をいただきました。最初に掲げた世界観が伝わり参加したくなるデザインであったとの、ひとつのしるしだと感じとても嬉しく思います。もちろん、運営に携わる皆さまの日々の活動の賜物であり集客には様々な要因が関わってくるので、自分が手放しで喜ぶのはちょっと違うと思いつつですが。この度は嬉しいご共有をしていただきありがとうございました！
      </PopupBody>

    </div>
  )
}

export default PopupVok
