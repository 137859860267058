import React from 'react'
import { isSmartPhone } from '../../utils/computerTerminal'
import "./CustomMousePointer.css"
import { XY } from '../../model/XY';
import MouseAppearance from './MouseAppearance';
import MousePointer from './MousePointer';

type Props = {
  mouseXY: XY;
  mouseClickFlag: number;
  showCustomMouse: boolean;
}

const CustomMousePointer: React.FC<Props> = ({ mouseXY, mouseClickFlag, showCustomMouse }) => {
  const isTouchDevice = isSmartPhone()
  const mouseWidth = 50
  const mouseHeight = mouseWidth / (14.686981 / 8.4957705)
  const appearanceWidth = 60
  const appearanceHeight = appearanceWidth / (26.180521 / 26.185812)
  const animationTime = 1000
  return (
    <>
      <div
        key={mouseClickFlag}
        style={{
          position: 'fixed', pointerEvents: "none", zIndex: 100000, left: mouseXY.x - mouseWidth * 0.5, top: mouseXY.y - mouseHeight * 0.5, width: "auto", height: mouseHeight,
          animation: mouseClickFlag === 2 ? "" : `mouseClickAnimation ${animationTime}ms ease-in-out`,
          strokeDasharray: 230,
        }}
      >
        {!isTouchDevice && showCustomMouse ? (
          <MousePointer />
        ) : <></>}
      </div>
      <div
        key={mouseClickFlag + 10}
        style={{
          position: 'fixed', pointerEvents: "none", zIndex: 100000, left: mouseXY.x - appearanceWidth * 0.5, top: mouseXY.y - appearanceHeight * 0.5, width: "auto", height: appearanceHeight,
          animation: mouseClickFlag === 2 ? "" : `mouseAppearanceAnimation ${animationTime * 3 / 8}ms ease-in-out`,
          transform: "scale(0)",
          animationDelay: `${animationTime * 0.1}ms`,
        }}
      >
        {!isTouchDevice && showCustomMouse ? (
          <MouseAppearance />
        ) : <></>}
      </div>
    </>
  )
}

export default CustomMousePointer