import React from 'react'
import { PopupBody, PopupBodySubHeadline } from './PopupBase'
import LinkText from '../../LinkText';

type Props = {
  fontResizeRate: number;
}

const PopupCassetteTape: React.FC<Props> = ({ fontResizeRate }) => {
  return (
    <div>
      <PopupBody fontResizeRate={fontResizeRate}>
        コーディングを用いて環境音楽を制作するアーティスト「Keita Miyano」のロゴマーク、アルバムアートワーク、名刺を制作しました。
      </PopupBody>

      <PopupBodySubHeadline fontResizeRate={fontResizeRate}>
        「み」から生まれたかたち
      </PopupBodySubHeadline>

      <PopupBody fontResizeRate={fontResizeRate}>
        言葉や感覚の言語化を通して論理的に思考と行動を重ねる側面。「どんなかたちが合うだろうか」と、感覚を頼りにいったん手を動かしてみる側面。自身のロゴ制作ではこの2つのあり方をいったりきたりしながら進めていきます。Keita Miyanoのヒアリングを踏まえて出てきたキーワードは「有機的な形・はみ出す・新しい何か・好奇心・カオス」など。日頃はどちらかというと論理の側面が多くなりやすいですが、今回の制作ではキーワードの自由さを意識し、感覚に重きを置いて制作を進めました。
        <br /><br />
        そうしてできたシンボルマークはKeita Miyanoの「み」から生まれた、はみ出していく曲線が主役のデザイン。Keita Miyanoの活動に通じるユニークさのあるかたちです。ロゴタイプにはガラスペンで描いた手書き文字を組み合わせ、デジタル（コーディング）を用いてアナログ（環境音楽）を制作する彼の活動を表現しています。
      </PopupBody>

      <PopupBodySubHeadline fontResizeRate={fontResizeRate}>
        電子音浴に浸る
      </PopupBodySubHeadline>

      <PopupBody fontResizeRate={fontResizeRate}>
        アルバムアートワークを作る際に目指したのは、Keita Miyanoの初めての個展「電子音浴 -中動態の環境音-」の空気をそのまま持ち帰れるカセットテープ。収録されている曲とともに「電子音浴」の世界に浸れるデザインを目指しました。アルバムタイトルはあえてケースの角の屈折する位置に配置し、写真が引き伸びてグラデーションになっているビジュアルのように、横に伸ばして見せています。カセットの内側にかかれたコードは、展示された全ての環境音の元になっている「サイン波」のコード。文字通り、展示の一部を持って帰れる、遊び心のあるアートワークです。
        <br /><br />
        電子音浴 -中動態の環境音-：デジタル音源
        <LinkText url="https://keitamiyano.bandcamp.com/album/electron-sound-bath" />
      </PopupBody>

      <PopupBodySubHeadline fontResizeRate={fontResizeRate}>
        名刺らしすぎない名刺
      </PopupBodySubHeadline>

      <PopupBody fontResizeRate={fontResizeRate}>
        しっかりした印象でありながらかしこまりすぎない、名刺らしすぎない名刺を目指して制作しました。シンボルマークが印象的なURLのみのシンプルな面は、まとめておいても様になるデザイン。情報面は必要な情報を載せつつも、作品の隣に置いてキャプションとしても成り立つような洗練された雰囲気に仕上げました。
      </PopupBody>

    </div>
  )
}

export default PopupCassetteTape
