import React, { useState, useEffect } from 'react'

type Props = {
  width: number;
  fill: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

const Prev: React.FC<Props> = ({ width, fill, onClick }) => {
  const height = width / (16.176624 / 32.353249)
  const [mouseHover, setMouseHover] = useState(false);
  const [opacity, setOpacity] = useState(1)

  useEffect(() => {
    setOpacity(mouseHover ? 0.7 : 1)
  }, [mouseHover])

  return (
    <div style={{ position: "fixed", left: 0, top: `calc( 50% - ${height * 0.5}px )`, height: height, width: "auto", cursor: "none" }}
      onClick={onClick}
      onMouseEnter={() => setMouseHover(true)}
      onMouseLeave={() => setMouseHover(false)}
    >
      <svg
        style={{ position: "relative", height: "100%" }}
        viewBox="0 0 16.176624 32.353249"
        version="1.1"
        id="svg1"
        xmlns="http://www.w3.org/2000/svg">
        <defs
          id="defs1" />
        <g
          id="layer1"
          transform="translate(-146.57917,-125.94167)">
          <g
            id="g259"
            transform="matrix(0.26458333,0,0,0.26458333,146.57917,6.6516267)">&#10;		<path
              className="st32"
              d="M 0,572.14 C 33.22,572.14 60.14,545.21 60.14,512 60.14,478.79 33.22,451.86 0,451.86"
              id="path258"
              style={{ fill: fill, fillOpacity: 0.8 * opacity, stroke: `rgba(34,34,34,${opacity})`, strokeWidth: 2, strokeMiterlimit: 10 }} />
            &#10;		<polygon
              className="st6"
              points="25.05,503.5 28.14,503.5 19.64,512 28.14,520.5 25.05,520.5 16.55,512 "
              id="polygon258"
              style={{ fill: `rgba(34,34,34,${opacity})` }} />
            &#10;	</g>
        </g>
      </svg>
    </div>
  )
}

export default Prev