import React from 'react'
import { PopupBody, PopupBodySubHeadline } from './PopupBase'

type Props = {
  fontResizeRate: number;
}

const PopupAsyncStudio: React.FC<Props> = ({ fontResizeRate }) => {
  return (
    <div>
      <PopupBody fontResizeRate={fontResizeRate}>
        音で新たな感覚を想起させる「async studio」のロゴマークを制作しました。
        <br /><br />
        シンボルマークは会社名の頭文字「a」、エイシンクではなく「アシンク」であることを強調する正面から見た「あ」と言う口の形と、なにかに合わせることのない動作=非同期を組み合わせたデザイン。「あ」と言う吹き出しのようにも見える、様々な解釈を委ねるような愛嬌のあるシンプルなかたちは、async studioの精神に通じています。
        <br /><br />
        ロゴタイプはシンボルマークから展開しています。部分部分のさりげなくシャープなニュアンスは芯を持ち自分の調子を大事にする姿勢、全体に共通するゆるやかな曲線は、自分の調子を他人の調子と合わせようとしているわけでないのに関わらず、自然と調和しているように見える様子を表現しています。
        <br /><br />
        async studioの由来と理想とするあり方を込めたロゴマークです。
      </PopupBody>
    </div>
  )
}

export default PopupAsyncStudio