import React, { useState, useEffect } from 'react'
import { map } from '../utils/math'

type ToggleProps = {
  style: React.CSSProperties;
  logoWidth: number;
  logoHeight: number;
  alignment: string;
  setAlignment: React.Dispatch<React.SetStateAction<string>>;
  setFirstViewFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const Toggle: React.FC<ToggleProps> = ({ style, logoWidth, logoHeight, alignment, setAlignment, setFirstViewFlag }) => {
  let copiedStyle = { ...style };
  Object.assign(copiedStyle, { position: "fixed", cursor: "none" });

  const [listviewRate, setListviewRate] = useState(0);
  const [mouseHover, setMouseHover] = useState(false);
  const [opacity, setOpacity] = useState(1)

  useEffect(() => {
    setOpacity(mouseHover ? 0.7 : 1)
  }, [mouseHover])

  useEffect(() => {
    if (alignment === "listview") {
      setFirstViewFlag(false);
      setTimeout(() => {
        if (listviewRate < 1) {
          setListviewRate(listviewRate + Math.min(0.1, 1 - listviewRate));
        } else {
          setListviewRate(1)
        }
      }, 1000 / 60);
    } else {
      setTimeout(() => {
        if (listviewRate > 0) {
          setListviewRate(listviewRate - Math.min(0.1, listviewRate));
        } else {
          setListviewRate(0)
        }
      }, 1000 / 60);
    }
  }, [alignment, listviewRate])
  return (
    <div
      style={copiedStyle}
      onClick={() => {
        setMouseHover(false)
        if (alignment === "roomview") {
          setAlignment("listview")
        } else {
          setAlignment("roomview")
        }
      }}
      onMouseEnter={() => setMouseHover(true)}
      onMouseLeave={() => setMouseHover(false)}
    >
      <svg
        style={{ position: "relative", height: "100%" }}
        viewBox="0 0 133.03728 32.51738"
        version="1.1"
        id="svg1"
        xmlns="http://www.w3.org/2000/svg">
        <defs
          id="defs1" />
        <g
          id="layer1"
          transform="translate(-39.687501,-135.84294)">
          <g
            id="g873"
            transform="matrix(1.006029,0,0,1.006029,-12.324198,-35.73279)">&#10;				<path
              className="st32"
              d="m 72.83,182.54 c 0,0 -4.43,0.35 -6.48,0.35 -2.05,0 -6.48,-0.35 -6.48,-0.35 l -0.07,2.04 c 0,0 4.5,-0.35 6.55,-0.35 2.05,0 6.55,0.35 6.55,0.35 z"
              id="path863"
              style={{ fill: `rgba(34,34,34,${opacity})` }} />
            &#10;				<path
              className="st32"
              d="m 66.35,170.55 c -2.44,-0.01 -3.6,0.01 -3.86,0.06 -0.98,0.2 -1.7,0.94 -1.91,1.98 -1.04,5.07 -1.97,18.59 -1.98,18.74 l 0.03,1.22 h 0.53 l 0.13,-1.19 c 0.03,-0.47 0.95,-13.67 1.97,-18.63 0.22,-1.09 1.03,-1.37 1.37,-1.44 0.14,-0.03 0.9,-0.06 3.72,-0.05 z"
              id="path864"
              style={{ fill: `rgba(34,34,34,${opacity})` }} />
            &#10;				<path
              className="st32"
              d="m 66.35,170.55 c 2.44,-0.01 3.6,0.01 3.86,0.06 0.98,0.2 1.7,0.94 1.91,1.98 1.04,5.07 1.97,18.59 1.98,18.74 l -0.03,1.22 h -0.53 l -0.13,-1.19 c -0.03,-0.47 -0.95,-13.67 -1.97,-18.63 -0.22,-1.09 -1.03,-1.37 -1.37,-1.44 -0.14,-0.03 -0.9,-0.06 -3.72,-0.05 z"
              id="path865"
              style={{ fill: `rgba(34,34,34,${opacity})` }} />
            &#10;				<polygon
              className="st32"
              points="60.87,174.91 62.62,186.48 62.62,187.22 62.12,187.22 61.9,186.43 60.62,176.9 "
              id="polygon865"
              style={{ fill: `rgba(34,34,34,${opacity})` }} />
            &#10;				<polygon
              className="st32"
              points="71.83,174.91 70.08,186.48 70.08,187.22 70.59,187.22 70.81,186.43 72.09,176.9 "
              id="polygon866"
              style={{ fill: `rgba(34,34,34,${opacity})` }} />
            &#10;				<path
              className="st33"
              d="m 66.35,183.13 c 3.21,-0.12 7.59,0 7.59,-1.05 -0.02,-1.52 -2.62,-1.89 -3.29,-1.89 -0.68,0 -1.23,-0.02 -1.91,0.02 -0.68,0.04 -2.38,0 -2.38,0 0,0 -1.7,0.04 -2.38,0 -0.68,-0.04 -1.24,-0.02 -1.91,-0.02 -0.68,0 -3.27,0.37 -3.29,1.89 -0.02,1.05 4.36,0.94 7.57,1.05 z"
              id="path866"
              style={{ fill: "#f4ede0" }} />
            &#10;				<path
              className="st32"
              d="m 66.35,182.96 c 3.21,-0.12 7.59,0 7.59,-1.05 -0.02,-1.52 -2.62,-1.89 -3.29,-1.89 -0.68,0 -1.23,-0.02 -1.91,0.02 -0.68,0.04 -2.38,0 -2.38,0 0,0 -1.7,0.04 -2.38,0 -0.68,-0.04 -1.24,-0.02 -1.91,-0.02 -0.68,0 -3.27,0.37 -3.29,1.89 -0.02,1.05 4.36,0.93 7.57,1.05 z"
              id="path867"
              style={{ fill: `rgba(34,34,34,${opacity})` }} />
            &#10;				<rect
              x="66.190002"
              y="171.24001"
              className="st32"
              width="0.33000001"
              height="8.8000002"
              id="rect867"
              style={{ fill: `rgba(34,34,34,${opacity})` }} />
            &#10;				<rect
              x="65.040001"
              y="171.24001"
              className="st32"
              width="0.33000001"
              height="8.8000002"
              id="rect868"
              style={{ fill: `rgba(34,34,34,${opacity})` }} />
            &#10;				<rect
              x="63.880001"
              y="171.24001"
              className="st32"
              width="0.33000001"
              height="8.8000002"
              id="rect869"
              style={{ fill: `rgba(34,34,34,${opacity})` }} />
            &#10;				<rect
              x="62.73"
              y="171.24001"
              className="st32"
              width="0.33000001"
              height="8.8000002"
              id="rect870"
              style={{ fill: `rgba(34,34,34,${opacity})` }} />
            &#10;				&#10;					<rect
              x="67.330002"
              y="171.24001"
              transform="rotate(180,67.4991,175.63935)"
              className="st32"
              width="0.33000001"
              height="8.8000002"
              id="rect871"
              style={{ fill: `rgba(34,34,34,${opacity})` }} />
            &#10;				&#10;					<rect
              x="68.489998"
              y="171.24001"
              transform="rotate(180,68.65135,175.63935)"
              className="st32"
              width="0.33000001"
              height="8.8000002"
              id="rect872"
              style={{ fill: `rgba(34,34,34,${opacity})` }} />
            &#10;				&#10;					<rect
              x="69.639999"
              y="171.24001"
              transform="rotate(180,69.8036,175.63935)"
              className="st32"
              width="0.33000001"
              height="8.8000002"
              id="rect873"
              style={{ fill: `rgba(34,34,34,${opacity})` }} />
            &#10;			</g>
          <path
            className="st32"
            d="M 134.88806,156.97203 H 83.590612 c -5.553281,0 -10.060293,-4.50703 -10.060293,-10.06031 v 0 c 0,-5.55328 4.507012,-10.06028 10.060293,-10.06028 H 134.878 c 5.55328,0 10.06029,4.507 10.06029,10.06028 v 0 c 0.01,5.55328 -4.49695,10.06031 -10.05023,10.06031 z"
            id="path873"
            style={{ fill: `rgba(34,34,34,${opacity})`, strokeWidth: 1.00603 }} />
          <circle
            className="st0"
            cx={`${map(listviewRate, 0, 1, 83.892418, 134.69234)}`}
            cy="146.91179"
            r="8.6317291"
            id="circle873"
            style={{ fill: "#f0eee7", strokeWidth: 1.00603 }} />
          <g
            id="g877"
            style={{ fill: `rgba(34,34,34,${opacity})` }}
            transform="matrix(1.006029,0,0,1.006029,-12.324198,-35.73279)">&#10;				<path
              d="m 52.44,201.47 v -2.95 c 0,-0.73 -0.13,-1 -0.74,-1.11 v -0.04 c 0.84,0 1.42,-0.14 1.82,-0.26 -0.04,0.78 -0.05,1.67 -0.05,2.37 h 0.04 c 0.2,-1.52 0.81,-2.4 1.77,-2.4 0.83,0 1.27,0.52 1.27,1.38 0,0.8 -0.45,1.5 -0.99,1.5 -0.15,0 -0.29,-0.04 -0.38,-0.11 0.31,-0.27 0.55,-0.9 0.55,-1.5 0,-0.63 -0.23,-0.98 -0.7,-0.98 -0.78,0 -1.5,1.43 -1.5,3.01 v 1.1 c 0,0.84 0.2,1.13 0.74,1.21 v 0.04 H 51.7 v -0.04 c 0.5,-0.09 0.74,-0.42 0.74,-1.22 z"
              id="path874" />
            &#10;				<path
              d="m 57.24,199.96 c 0,-1.83 1.11,-2.89 2.92,-2.89 1.8,0 2.92,1.07 2.92,2.89 0,1.82 -1.11,2.89 -2.92,2.89 -1.81,0 -2.92,-1.06 -2.92,-2.89 z m 2.91,2.74 c 1.07,0 1.77,-0.98 1.77,-2.74 0,-1.75 -0.7,-2.74 -1.77,-2.74 -1.07,0 -1.78,0.99 -1.78,2.74 0,1.76 0.71,2.74 1.78,2.74 z"
              id="path875" />
            &#10;				<path
              d="m 63.92,199.96 c 0,-1.83 1.11,-2.89 2.92,-2.89 1.8,0 2.92,1.07 2.92,2.89 0,1.82 -1.11,2.89 -2.92,2.89 -1.81,0 -2.92,-1.06 -2.92,-2.89 z m 2.92,2.74 c 1.07,0 1.77,-0.98 1.77,-2.74 0,-1.75 -0.7,-2.74 -1.77,-2.74 -1.07,0 -1.78,0.99 -1.78,2.74 0,1.76 0.71,2.74 1.78,2.74 z"
              id="path876" />
            &#10;				<path
              d="m 71.29,201.47 v -2.92 c 0,-0.79 -0.17,-1.03 -0.74,-1.14 v -0.04 c 0.66,0 1.25,-0.09 1.82,-0.26 -0.03,0.84 -0.05,1.72 -0.05,2.37 h 0.05 c 0.11,-1.55 0.87,-2.4 2.13,-2.4 1.3,0 1.93,0.79 1.87,2.39 0.11,-1.53 0.86,-2.39 2.13,-2.39 1.27,0 1.88,0.74 1.88,2.25 v 2.16 c 0,0.88 0.22,1.13 0.74,1.21 v 0.04 h -2.51 v -0.04 c 0.5,-0.08 0.69,-0.37 0.69,-1.21 v -2.02 c 0,-1.29 -0.38,-1.85 -1.23,-1.85 -1.09,0 -1.69,1.07 -1.69,2.74 v 1.13 c 0,0.88 0.21,1.13 0.68,1.21 v 0.04 h -2.45 v -0.04 c 0.48,-0.08 0.68,-0.37 0.68,-1.21 v -2.06 c 0,-1.28 -0.39,-1.82 -1.22,-1.82 -1.08,0 -1.71,1.04 -1.71,2.74 v 1.13 c 0,0.86 0.19,1.12 0.69,1.21 v 0.04 h -2.51 v -0.04 c 0.52,-0.1 0.75,-0.42 0.75,-1.22 z"
              id="path877" />
            &#10;			</g>
          <g
            id="g880"
            transform="matrix(1.006029,0,0,1.006029,-12.324198,-35.73279)">&#10;				<path
              className="st32"
              d="m 166.97,173.6 v 0.94 c 0,0.11 0.09,0.2 0.2,0.2 h 16.29 c 0.11,0 0.2,-0.09 0.2,-0.2 v -0.94 c 0,-0.11 -0.09,-0.2 -0.2,-0.2 h -16.29 c -0.11,0 -0.2,0.09 -0.2,0.2 z"
              id="path878"
              style={{ fill: `rgba(34,34,34,${opacity})` }} />
            &#10;				<path
              className="st32"
              d="m 166.97,178.25 v 0.94 c 0,0.11 0.09,0.2 0.2,0.2 h 16.29 c 0.11,0 0.2,-0.09 0.2,-0.2 v -0.94 c 0,-0.11 -0.09,-0.2 -0.2,-0.2 h -16.29 c -0.11,0 -0.2,0.09 -0.2,0.2 z"
              id="path879"
              style={{ fill: `rgba(34,34,34,${opacity})` }} />
            &#10;				<path
              className="st32"
              d="m 175.32,182.89 v 0.94 c 0,0.11 0.09,0.2 0.2,0.2 h 7.95 c 0.11,0 0.2,-0.09 0.2,-0.2 v -0.94 c 0,-0.11 -0.09,-0.2 -0.2,-0.2 h -7.95 c -0.11,0 -0.2,0.09 -0.2,0.2 z"
              id="path880"
              style={{ fill: `rgba(34,34,34,${opacity})` }} />
            &#10;			</g>
          <g
            id="g884"
            style={{ fill: `rgba(34,34,34,${opacity})` }}
            transform="matrix(1.006029,0,0,1.006029,-12.324198,-35.73279)">&#10;				<path
              d="m 167.48,201.48 v -5.3 c 0,-0.71 -0.17,-1.03 -0.77,-1.09 v -0.04 c 0.7,0 1.4,-0.12 1.97,-0.37 l 0.01,0.02 c -0.08,0.28 -0.12,0.79 -0.12,1.39 v 5.4 c 0,0.8 0.15,1.11 0.73,1.2 v 0.04 h -2.54 v -0.04 c 0.54,-0.1 0.72,-0.46 0.72,-1.21 z"
              id="path881" />
            &#10;				<path
              d="m 169.89,202.68 c 0.51,-0.09 0.74,-0.41 0.74,-1.21 v -3 c 0,-0.73 -0.14,-1 -0.74,-1.1 v -0.04 c 0.74,0 1.46,-0.1 1.96,-0.23 l 0.02,0.03 c -0.1,0.26 -0.17,0.76 -0.17,1.25 v 3.09 c 0,0.86 0.19,1.12 0.69,1.21 v 0.04 h -2.51 v -0.04 z m 0.46,-7.17 0.76,-0.76 0.76,0.76 -0.76,0.76 z"
              id="path882" />
            &#10;				<path
              d="m 173.08,201.46 c 0,-0.43 0.23,-0.71 0.61,-0.71 0.1,0 0.18,0.01 0.24,0.04 -0.1,0.2 -0.17,0.44 -0.17,0.66 0,0.74 0.69,1.25 1.82,1.25 1.07,0 1.71,-0.47 1.71,-1.23 0,-0.55 -0.33,-0.84 -1.31,-1.02 l -1.32,-0.25 c -0.91,-0.18 -1.44,-0.57 -1.44,-1.42 0,-0.98 0.88,-1.71 2.55,-1.71 1.5,0 2.44,0.58 2.44,1.41 0,0.45 -0.3,0.73 -0.65,0.73 -0.07,0 -0.13,-0.01 -0.17,-0.03 0.08,-0.12 0.15,-0.37 0.15,-0.62 0,-0.78 -0.67,-1.33 -1.76,-1.33 -1.08,0 -1.67,0.43 -1.67,1.12 0,0.58 0.34,0.83 1.34,1 l 1.22,0.24 c 1.17,0.22 1.65,0.64 1.65,1.45 0,1.09 -1.02,1.82 -2.74,1.82 -1.64,-0.01 -2.5,-0.52 -2.5,-1.4 z"
              id="path883" />
            &#10;				<path
              d="m 179.92,200.68 v -3.3 h -1.01 v -0.11 c 1.57,-0.04 1.91,-0.08 2.08,-1.58 h 0.1 l -0.04,1.52 h 2.41 v 0.19 l -2.41,-0.01 v 3.45 c 0,1.34 0.39,1.83 1.07,1.83 0.64,0 1.16,-0.54 1.16,-1.53 0,-0.65 -0.25,-1.12 -0.67,-1.26 0.1,-0.07 0.27,-0.11 0.44,-0.11 0.53,0 0.89,0.45 0.89,1.11 0,1.07 -0.81,1.99 -2.08,1.99 -1.29,-0.02 -1.94,-0.74 -1.94,-2.19 z"
              id="path884" />
            &#10;			</g>
        </g>
      </svg>
    </div>
  )
}

export default Toggle