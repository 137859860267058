import React, { useState } from 'react'

type Props = {
  url: string;
  customText?: string;
}

const LinkText: React.FC<Props> = ({ url, customText = url }) => {
  const [mouseHover, setMouseHover] = useState(false);
  return (
    <a
      href={url}
      target="_blank" rel="noopener noreferrer"
      style={{ cursor: "none", backgroundColor: mouseHover ? `rgba(${233 * 0.6}, ${231 * 0.6}, ${220 * 0.6}, 0.25)` : "rgba(0,0,0,0)" }}
      onMouseEnter={() => setMouseHover(true)}
      onMouseLeave={() => setMouseHover(false)}
    >
      {customText}
    </a>
  )
}

export default LinkText