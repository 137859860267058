import React from 'react'

const HorizontalDivider = () => {
  return (
    <svg
      viewBox="0 0 151.58507 1.1244792"
      version="1.1"
      id="svg2859"
      xmlns="http://www.w3.org/2000/svg">
      <defs
        id="defs2856" />
      <g
        id="layer1"
        transform="translate(-28.311977,-131.57759)">
        <g
          id="g1812"
          transform="matrix(0.26458333,0,0,0.26458333,4.8857683,-44.338575)">
          <rect
            x="660.53998"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1626"
            style={{fill:"#222222"}} />

          <rect
            x="654.32001"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1628"
            style={{fill:"#222222"}} />

          <rect
            x="648.10999"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1630"
            style={{fill:"#222222"}} />

          <rect
            x="641.89001"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1632"
            style={{fill:"#222222"}} />

          <rect
            x="635.66998"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1634"
            style={{fill:"#222222"}} />

          <rect
            x="629.46002"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1636"
            style={{fill:"#222222"}} />

          <rect
            x="623.23999"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1638"
            style={{fill:"#222222"}} />

          <rect
            x="617.02002"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1640"
            style={{fill:"#222222"}} />

          <rect
            x="610.79999"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1642"
            style={{fill:"#222222"}} />

          <rect
            x="604.58002"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1644"
            style={{fill:"#222222"}} />

          <rect
            x="598.37"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1646"
            style={{fill:"#222222"}} />

          <rect
            x="592.15002"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1648"
            style={{fill:"#222222"}} />

          <rect
            x="585.92999"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1650"
            style={{fill:"#222222"}} />

          <rect
            x="579.71997"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1652"
            style={{fill:"#222222"}} />

          <rect
            x="573.5"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1654"
            style={{fill:"#222222"}} />

          <rect
            x="567.28003"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1656"
            style={{fill:"#222222"}} />

          <rect
            x="561.06"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1658"
            style={{fill:"#222222"}} />

          <rect
            x="554.84998"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1660"
            style={{fill:"#222222"}} />

          <rect
            x="548.63"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1662"
            style={{fill:"#222222"}} />

          <rect
            x="542.40997"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1664"
            style={{fill:"#222222"}} />

          <rect
            x="536.19"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1666"
            style={{fill:"#222222"}} />

          <rect
            x="529.97998"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1668"
            style={{fill:"#222222"}} />

          <rect
            x="523.76001"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1670"
            style={{fill:"#222222"}} />

          <rect
            x="517.53998"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1672"
            style={{fill:"#222222"}} />

          <rect
            x="511.32001"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1674"
            style={{fill:"#222222"}} />

          <rect
            x="505.10999"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1676"
            style={{fill:"#222222"}} />

          <rect
            x="498.89001"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1678"
            style={{fill:"#222222"}} />

          <rect
            x="492.67001"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1680"
            style={{fill:"#222222"}} />

          <rect
            x="486.45999"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1682"
            style={{fill:"#222222"}} />

          <rect
            x="480.23999"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1684"
            style={{fill:"#222222"}} />

          <rect
            x="474.01999"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1686"
            style={{fill:"#222222"}} />

          <rect
            x="467.79999"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1688"
            style={{fill:"#222222"}} />

          <rect
            x="461.57999"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1690"
            style={{fill:"#222222"}} />

          <rect
            x="455.37"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1692"
            style={{fill:"#222222"}} />

          <rect
            x="449.14999"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1694"
            style={{fill:"#222222"}} />

          <rect
            x="442.92999"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1696"
            style={{fill:"#222222"}} />

          <rect
            x="436.72"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1698"
            style={{fill:"#222222"}} />

          <rect
            x="430.5"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1700"
            style={{fill:"#222222"}} />

          <rect
            x="424.28"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1702"
            style={{fill:"#222222"}} />

          <rect
            x="418.06"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1704"
            style={{fill:"#222222"}} />

          <rect
            x="411.85001"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1706"
            style={{fill:"#222222"}} />

          <rect
            x="405.63"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1708"
            style={{fill:"#222222"}} />

          <rect
            x="399.41"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1710"
            style={{fill:"#222222"}} />

          <rect
            x="393.19"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1712"
            style={{fill:"#222222"}} />

          <rect
            x="386.98001"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1714"
            style={{fill:"#222222"}} />

          <rect
            x="380.76001"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1716"
            style={{fill:"#222222"}} />

          <rect
            x="374.54001"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1718"
            style={{fill:"#222222"}} />

          <rect
            x="368.32001"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1720"
            style={{fill:"#222222"}} />

          <rect
            x="362.10999"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1722"
            style={{fill:"#222222"}} />

          <rect
            x="355.89001"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1724"
            style={{fill:"#222222"}} />

          <rect
            x="349.67001"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1726"
            style={{fill:"#222222"}} />

          <rect
            x="343.45999"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1728"
            style={{fill:"#222222"}} />

          <rect
            x="337.23999"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1730"
            style={{fill:"#222222"}} />

          <rect
            x="331.01999"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1732"
            style={{fill:"#222222"}} />

          <rect
            x="324.79999"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1734"
            style={{fill:"#222222"}} />

          <rect
            x="318.57999"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1736"
            style={{fill:"#222222"}} />

          <rect
            x="312.37"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1738"
            style={{fill:"#222222"}} />

          <rect
            x="306.14999"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1740"
            style={{fill:"#222222"}} />

          <rect
            x="299.92999"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1742"
            style={{fill:"#222222"}} />

          <rect
            x="293.72"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1744"
            style={{fill:"#222222"}} />

          <rect
            x="287.5"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1746"
            style={{fill:"#222222"}} />

          <rect
            x="281.28"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1748"
            style={{fill:"#222222"}} />

          <rect
            x="275.06"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1750"
            style={{fill:"#222222"}} />

          <rect
            x="268.85001"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1752"
            style={{fill:"#222222"}} />

          <rect
            x="262.63"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1754"
            style={{fill:"#222222"}} />

          <rect
            x="256.41"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1756"
            style={{fill:"#222222"}} />

          <rect
            x="250.19"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1758"
            style={{fill:"#222222"}} />

          <rect
            x="243.98"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1760"
            style={{fill:"#222222"}} />

          <rect
            x="237.75999"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1762"
            style={{fill:"#222222"}} />

          <rect
            x="231.53999"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1764"
            style={{fill:"#222222"}} />

          <rect
            x="225.32001"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1766"
            style={{fill:"#222222"}} />

          <rect
            x="219.11"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1768"
            style={{fill:"#222222"}} />

          <rect
            x="212.89"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1770"
            style={{fill:"#222222"}} />

          <rect
            x="206.67"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1772"
            style={{fill:"#222222"}} />

          <rect
            x="200.46001"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1774"
            style={{fill:"#222222"}} />

          <rect
            x="194.24001"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1776"
            style={{fill:"#222222"}} />

          <rect
            x="188.02"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1778"
            style={{fill:"#222222"}} />

          <rect
            x="181.8"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1780"
            style={{fill:"#222222"}} />

          <rect
            x="175.58"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1782"
            style={{fill:"#222222"}} />

          <rect
            x="169.37"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1784"
            style={{fill:"#222222"}} />

          <rect
            x="163.14999"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1786"
            style={{fill:"#222222"}} />

          <rect
            x="156.92999"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1788"
            style={{fill:"#222222"}} />

          <rect
            x="150.72"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1790"
            style={{fill:"#222222"}} />

          <rect
            x="144.5"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1792"
            style={{fill:"#222222"}} />

          <rect
            x="138.28"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1794"
            style={{fill:"#222222"}} />

          <rect
            x="132.06"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1796"
            style={{fill:"#222222"}} />

          <rect
            x="125.85"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1798"
            style={{fill:"#222222"}} />

          <rect
            x="119.63"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1800"
            style={{fill:"#222222"}} />

          <rect
            x="113.41"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1802"
            style={{fill:"#222222"}} />

          <rect
            x="107.19"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1804"
            style={{fill:"#222222"}} />

          <rect
            x="100.98"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1806"
            style={{fill:"#222222"}} />

          <rect
            x="94.760002"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1808"
            style={{fill:"#222222"}} />

          <rect
            x="88.540001"
            y="664.88"
            className="st2"
            width="0.92000002"
            height="4.25"
            id="rect1810"
            style={{fill:"#222222"}} />

        </g>
      </g>
    </svg>
  )
}

export default HorizontalDivider