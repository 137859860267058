import React from 'react'
import { PopupBody, PopupBodySubHeadline } from './PopupBase'

type Props = {
  fontResizeRate: number;
}

const PopupLeaflet: React.FC<Props> = ({ fontResizeRate }) => {
  return (
    <div>
      <PopupBody fontResizeRate={fontResizeRate}>
        岐阜美濃加茂の商店街から誕生したお芋スイーツブランド「Creaimo」のリーフレットを制作しました。
        <br /><br />
        店舗情報が明確に伝わることを最大の目的に、このリーフレットを片手に店舗へ訪れたくなるようなデザインを目指しています。ヒアリングでお伺いした内容を踏まえて、どなたでもポケットに入れてふらりと持ち帰りやすい手のひらに収まるA4クロス8p折り（折りたたんだ際にはA6サイズ）を提案させていただきました。
        <br /><br />
        自身の考えるクロス8p折りのメリットは3点あります。開く際に「①左右に開く②上下に開く」の2手順を踏むので、読む順番が分かりやすい点。内面を見開き1面で大きく使えるため、creaimoの世界観をたっぷりと印象的に出せる点。開くたび紙面の大きさが変わるため、レイアウトに変化をつけやすくリズムよく読みやすい点。今回目指すデザインにぴったりなこの形状を起点に、構成やデザインを考えていきました。
        <br /><br />
        表紙はロゴ、どんなブランドなのか端的に説明するテキストとシズル感のある写真を大きく配置し、ひと目で何のお店か分かるデザインです。ぺらりとめくったp.2とp.3には目の前で商品を炙っている写真を目一杯広げた上に、ブランドについてのテキストを置き、文字情報と視覚情報の両側からCreaimoの特徴が伝わるようにしています。見開き1面でデザインした内面は店舗らしさを感じる写真を大きく配置し、商品はもちろんお店自体に興味を持っていただける内容を目指しました。
        <br /><br />
        内面はリーフレットとしてだけでなく、店舗のガラス面に貼ってポスターとして店舗の広報を担えるデザインにしています。定休日に店舗を訪れた方や、その日は寄れなくても通りがかった方へ情報をお伝えできるやさしい設計です。表紙と対になる裏表紙には、お店に足を運んでいただく際に必要な情報をコンパクトにまとめています。
        <br /><br />
        Creaimoの世界観たっぷりにロゴやwebサイト等の雰囲気を踏襲しつつも、実際に店舗に行ってみたくなる親しみやすさを含ませながら雑誌のようなおしゃれさのあるデザインに仕上げました。
        <br /><br />
        ※画像のリーフレットに掲載している情報は2023年6月現在のものです。最新情報は公式サイトをご確認ください。
        <br /><br />
        Client：株式会社IDENTITY
      </PopupBody>

    </div>
  )
}

export default PopupLeaflet