import React from 'react'
import { PopupBody, PopupBodySubHeadline } from './PopupBase'

type Props = {
  fontResizeRate: number;
}

const PopupPhotoOnWall: React.FC<Props> = ({ fontResizeRate }) => {
  return (
    <div>
      <PopupBody fontResizeRate={fontResizeRate}>
        本との出会い、人との出会いをお届けする「Chapters書店」のアートワークを制作しました。3月のテーマ「春一番」から選書された桜の描写が美しい小説4作品をそれぞれ表現する写真に、ガラスペンで小説の一節を綴ったアートワークです。
        <br /><br />
        Chapters書店さまからいただいた選書の1冊1冊に対するイメージと対話を踏まえた上で、自身の中でイメージを膨らませ、アートワークに落とし込んでいきました。元となった小説の一節とトーンに忠実であることが7割、そこに自身の解釈を加え表現することが3割の心持ちで制作しています。
        <br /><br />
        著者とタイトルが伏せられた4つのアートワークから好みのものを1つ選ぶと、そのアートワークの元となった本が届く。自分で本を選ぶのとは異なる、未知との出会いとわくわくする体験がさらに高まるようなアートワークを目指しています。
        <br /><br />
        またテーマを担当した3月の約1ヶ月の間、Chapters書店さまの期間限定の棚展開にて、制作した4つのアートワークも展示していただきました。
        <br /><br />
        Client：株式会社MISSION ROMANTIC
      </PopupBody>
    </div>
  )
}

export default PopupPhotoOnWall