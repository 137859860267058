import React from 'react'
import ListBase from './Portfolios/Lists/ListBase'
import { useWindowDimensions } from '../utils/windowDimensions'
import Grid from '@mui/material/Grid';
import { portfoliosOrderListView, portfolioDetails } from '../consts/PortfolioDetails';
import { PortfolioType } from '../model/Portfolio';

type ListProps = {
  portfolioType: PortfolioType;
  setShowPopupBase: React.Dispatch<React.SetStateAction<boolean>>;
  setPortfolioType: React.Dispatch<React.SetStateAction<PortfolioType>>;
}

const List: React.FC<ListProps> = ({ portfolioType, setShowPopupBase, setPortfolioType }) => {
  return (
    <ListBase
      portfolioDetail={portfolioDetails[portfolioType]}
      setShowPopupBase={setShowPopupBase}
      portfolioType={portfolioType}
      setPortfolioType={setPortfolioType}
    />
  )
}

type ListViewProps = {
  headerHeight: number;
  showPopupBase: boolean;
  setShowPopupBase: React.Dispatch<React.SetStateAction<boolean>>;
  setPortfolioType: React.Dispatch<React.SetStateAction<PortfolioType>>;
}

const ListView: React.FC<ListViewProps> = ({ headerHeight, showPopupBase, setShowPopupBase, setPortfolioType }) => {
  const { height, width } = useWindowDimensions()
  return (
    <Grid container direction="row" justifyContent="space-evenly" alignItems="flex-start" style={{
      paddingTop: "20px",
      paddingBottom: "20px",
      position: "fixed",
      top: headerHeight,
      width: width,
      maxHeight: height - headerHeight,
      height: "auto",
      overflowY: "scroll",
    }}>
      {portfoliosOrderListView.map((portfolioType) => {
        return (
          <List
            key={portfolioType}
            portfolioType={portfolioType}
            setShowPopupBase={setShowPopupBase}
            setPortfolioType={setPortfolioType}
          />
        )
      })}
    </Grid>
  )
}

export default ListView