import React from 'react'

type Props = {
  left: number;
  bottom: number;
  height: number;
  width: number;
  opacityRate: number;
}

const EmptyStand: React.FC<Props> = ({ left, bottom, height, width, opacityRate }) => {
  return (
    <div>
      <div style={{ position: "absolute", opacity: opacityRate, left: left - width / 2, bottom: bottom, width: "auto", height: height }}>
        <img style={{ position: "relative", height: "100%", pointerEvents: "none", userSelect: "none" }} src="/svgs/empty_stand.svg" alt="empty_stand" />
      </div>
    </div>
  )
}

export default EmptyStand