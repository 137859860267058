import React from 'react'
import { PopupBody, PopupBodySubHeadline } from './PopupBase'

type Props = {
  fontResizeRate: number;
}

const PopupPendentif: React.FC<Props> = ({ fontResizeRate }) => {
  return (
    <div>
      <PopupBody fontResizeRate={fontResizeRate}>
        「孤独な白鯨が海で見つけた宝物」をコンセプトに活動する装身具店「52Hz.」のロゴとアクセサリー台紙、個展の告知用ポスターとポストカードを制作しました。
      </PopupBody>

      <PopupBodySubHeadline fontResizeRate={fontResizeRate}>
        52Hz.の物語を宿すロゴ
      </PopupBodySubHeadline>

      <PopupBody fontResizeRate={fontResizeRate}>
        ロゴタイプは「52」を主役に、海面に浮かぶ泡沫のきらめきと波のゆらぎから形作られた儚い美しさのあるデザインです。ブランドコンセプト「孤独な白鯨が海で見つけた宝物」と、52Hz.のアクセサリーたちが持つ美しさの表現を目指しています。
        <br /><br />
        ヒアリングでお伺いさせていただいた52Hz.の世界観はとても鮮明でブレがなく、またブランドを主催する四ノ宮さんの52Hz.への愛がひしひしと伝わってきました。孤独な白鯨が泳ぐ海は、どんな海なのか。朝なのか、夜なのか。宝物にはどんなアイテムがあるのか。ブランドコンセプト「孤独な白鯨が海で見つけた宝物」を紐解き、対話を通じて言葉とイメージを広げたり深めたりしながら、四ノ宮さんの描く52Hz.の輪郭をより明確に捉えられるよう意識し制作を進めました。
        <br /><br />
        ブランドカラーは繊細さと透明感を持ち合わせながら、こっくりとした重たさのある花浅葱色。52Hz.の世界観はもちろん、写真家でもある四ノ宮さんが映す青や儚さとも照らし合わせながら選びました。印象的な「52」は並べる事でテキスタイルとしても使用できます。
      </PopupBody>

      <PopupBodySubHeadline fontResizeRate={fontResizeRate}>
        物語の始まりを予感する<wbr />アクセサリー台紙
      </PopupBodySubHeadline>

      <PopupBody fontResizeRate={fontResizeRate}>
        主役のアクセサリーたちを引き立てる、最高の二番手を目指したアクセサリー台紙です。52Hz.のアクセサリー1点1点に物語があることから、本の表紙を彷彿とさせるデザインを制作しました。コットン紙にロゴ制作時に制定したブランドカラー花浅葱色でしっかりと凹みを感じられる活版印刷を施し、手触り感のある仕上がり。アクセサリーをお迎えした際の喜びが高まるような非日常感、特別感を目指しています。
      </PopupBody>

      <PopupBodySubHeadline fontResizeRate={fontResizeRate}>
        52Hz.のアクセサリーと<wbr />巡り合う日のための招待状
      </PopupBodySubHeadline>

      <PopupBody fontResizeRate={fontResizeRate}>
        2023年2月、長くオンラインショップで展開してきた52Hz.の初めての個展が開催されました。いつも画面の向こうで寄り添ってくださる皆さまと初めましての皆さまへ向けて、52Hz.の世界観に浸れる招待状のようなデザインを目指してポスターとポストカードを制作しました。
        <br /><br />
        暗くて見えない海の中で、たったひとつ落ちてくるキラキラしたもの。 一等星ではないけれど、儚くも美しいかけらの光。個展名「海淵の星屑（かいえんのほしくず）」についてお伺いした際の言葉たちと、52Hz.さんのアクセサリーたちから感じる、儚い美しさを散りばめるようにデザインしています。
      </PopupBody>
    </div>
  )
}

export default PopupPendentif
