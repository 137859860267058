import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import RoomView from './components/RoomView';
import ListView from './components/ListView';
import { useWindowDimensions } from './utils/windowDimensions'
import { getPrevNextElementsFromArray, CurrentElements } from './utils/array';
import Popup from './components/Portfolios/Popups/Popup';
import { map } from './utils/math';
import Next from './components/Next';
import Prev from './components/Prev';
import { portfoliosOrderRoomView, portfoliosOrderListView } from './consts/PortfolioDetails';
import { PortfolioType, PortfolioTitle, portfolioMapping, portfolioMappingRev } from './model/Portfolio';
import { isSmartPhone } from './utils/computerTerminal'
import CustomMousePointer from './components/MousePointers/CustomMousePointer';
import { XY } from './model/XY';
import { BrowserRouter, Routes, Route } from "react-router-dom";

const overlayBackgroundColor = `rgba(${233 * 0.6}, ${231 * 0.6}, ${220 * 0.6}, 0.25)`
type PrevNext = "" | "prev" | "next"

type Props = {
  targetPage: PortfolioTitle;
}

const PortfolioSite: React.FC<Props> = ({ targetPage }) => {
  const { width, height, verticalFlag } = useWindowDimensions()
  const isTouchDevice = isSmartPhone()
  const [firstViewFlag, setFirstViewFlag] = useState(true);
  const [opacityRate, setOpacityRate] = useState(0);
  const [alignment, setAlignment] = useState<string>('roomview');
  const [refPosition, setRefPosition] = useState(325);
  const [showPopupBase, setShowPopupBase] = useState(targetPage !== "");
  const [portfolioType, setPortfolioType] = useState<PortfolioType>(portfolioMapping[targetPage])
  const [prevNext, setPrevNext] = useState<PrevNext>("")
  const [keyIndex, setKeyIndex] = useState(0)
  const [mouseXY, setMouseXY] = useState<XY>({ x: 0, y: 0 })
  const [mouseClickFlag, setMouseClickFlag] = useState(2)
  const [showCustomMouse, setShowCustomMouse] = useState(false)
  const headerHeight = Math.max(height * 0.11, 73.37)
  const [currentPortfolioTypeInfo, setCurrentPortfolioTypeInfo] = useState<CurrentElements>({
    previousElement: "",
    currentElement: "",
    nextElement: "",
  })
  const prevNextIconWidth = verticalFlag ? 40 : Math.min(map(width, 650, 1500, 40, 80), 80)
  const onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const mouseX = e.pageX;
    const mouseY = e.pageY;
    setMouseXY({
      x: mouseX,
      y: mouseY,
    })
  }
  const PrevClick = () => {
    setCurrentPortfolioTypeInfo(getPrevNextElementsFromArray(alignment === "roomview" ? portfoliosOrderRoomView : portfoliosOrderListView, currentPortfolioTypeInfo.previousElement))
    setPortfolioType(currentPortfolioTypeInfo.previousElement)
    setPrevNext("prev")
    setKeyIndex(1 - keyIndex)
  }
  const NextClick = () => {
    setCurrentPortfolioTypeInfo(getPrevNextElementsFromArray(alignment === "roomview" ? portfoliosOrderRoomView : portfoliosOrderListView, currentPortfolioTypeInfo.nextElement))
    setPortfolioType(currentPortfolioTypeInfo.nextElement)
    setPrevNext("next")
    setKeyIndex(1 - keyIndex)
  }
  useEffect(() => {
    setCurrentPortfolioTypeInfo(getPrevNextElementsFromArray(alignment === "roomview" ? portfoliosOrderRoomView : portfoliosOrderListView, portfolioType))
    window.history.replaceState(null, '', `/${portfolioMappingRev[portfolioType]}`);
  }, [portfolioType])
  useEffect(() => {
    if (!showPopupBase) {
      setPrevNext("")
      setKeyIndex(1 - keyIndex)
      window.history.replaceState(null, '', `/`);
      setPortfolioType("")
    }
  }, [showPopupBase])
  useEffect(() => {
    setTimeout(() => {
      if (opacityRate < 1) {
        setOpacityRate(opacityRate + 0.025);
      } else {
        setOpacityRate(1)
      }
    }, 1000 / 60);
  }, [opacityRate])
  return (
    <div
      className="App"
      style={{ textAlign: "center", width: "auto", height: height }}
      onMouseMove={(e) => { if (!isTouchDevice) { onMouseMove(e) } }}
      onMouseEnter={() => setShowCustomMouse(true)}
      onMouseLeave={() => setShowCustomMouse(false)}
      onClick={() => setMouseClickFlag(mouseClickFlag !== 0 ? 0 : 1)}
      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "ArrowLeft") {
          PrevClick();
        }
        if (e.key === "ArrowRight") {
          NextClick();
        }
      }}
    >
      <CustomMousePointer
        mouseXY={mouseXY}
        mouseClickFlag={mouseClickFlag}
        showCustomMouse={showCustomMouse}
      />
      <div style={{ overflowY: "hidden" }}>
        {alignment === "roomview" ? (
          <RoomView
            firstViewFlag={firstViewFlag}
            setFirstViewFlag={setFirstViewFlag}
            opacityRate={opacityRate}
            furnitureFallRate={1}
            portfolioFallRate={1}
            refPosition={refPosition}
            setRefPosition={setRefPosition}
            currentPortfolioType={currentPortfolioTypeInfo.currentElement}
            showPopupBase={showPopupBase}
            setShowPopupBase={setShowPopupBase}
            portfolioType={portfolioType}
            setPortfolioType={setPortfolioType}
          />
        ) : alignment === "listview" ? (
          <ListView
            headerHeight={headerHeight}
            showPopupBase={showPopupBase}
            setShowPopupBase={setShowPopupBase}
            setPortfolioType={setPortfolioType}
          />
        ) : <></>}
        {showPopupBase ? (
          <div style={{ position: "fixed", height: height, width: width, backgroundColor: overlayBackgroundColor }} />
        ) : <></>}
        <Popup
          key={keyIndex}
          modalMove={prevNext === "prev" ? "OutToR" : prevNext === "next" ? "OutToL" : ""}
          portfolioType={prevNext === "prev" ? currentPortfolioTypeInfo.nextElement : prevNext === "next" ? currentPortfolioTypeInfo.previousElement : currentPortfolioTypeInfo.currentElement}
          showPopupBase={prevNext === "" ? false : showPopupBase}
          setShowPopupBase={setShowPopupBase}
          headerHeight={headerHeight}
          setMouseXY={setMouseXY}
        />
        <Popup
          key={keyIndex + 10}
          modalMove={prevNext === "prev" ? "InFromL" : prevNext === "next" ? "InFromR" : ""}
          portfolioType={portfolioType}
          showPopupBase={showPopupBase}
          setShowPopupBase={setShowPopupBase}
          headerHeight={headerHeight}
          setMouseXY={setMouseXY}
        />
        {showPopupBase ? (
          <div style={{ position: "fixed", zIndex: 10 }}>
            <Prev width={prevNextIconWidth} fill={"rgba(233, 231, 220, 0.8)"} onClick={PrevClick} />
            <Next width={prevNextIconWidth} fill={"rgba(233, 231, 220, 0.8)"} onClick={NextClick} />
          </div>
        ) : <></>}
        <Header
          headerHeight={headerHeight}
          alignment={alignment}
          setAlignment={setAlignment}
          setFirstViewFlag={setFirstViewFlag}
        />
      </div>
    </div>
  );
}

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PortfolioSite targetPage='' />} />
        <Route path="/aboutme" element={<PortfolioSite targetPage='aboutme' />} />
        <Route path="/anjumori" element={<PortfolioSite targetPage='anjumori' />} />
        <Route path="/asyncstudio" element={<PortfolioSite targetPage='asyncstudio' />} />
        <Route path="/necotto" element={<PortfolioSite targetPage='necotto' />} />
        <Route path="/vok" element={<PortfolioSite targetPage='vok' />} />
        <Route path="/kutanisankai" element={<PortfolioSite targetPage='kutanisankai' />} />
        <Route path="/playlist" element={<PortfolioSite targetPage='playlist' />} />
        <Route path="/kinzangama" element={<PortfolioSite targetPage='kinzangama' />} />
        <Route path="/creaimo" element={<PortfolioSite targetPage='creaimo' />} />
        <Route path="/keitamiyano" element={<PortfolioSite targetPage='keitamiyano' />} />
        <Route path="/wallpaper" element={<PortfolioSite targetPage='wallpaper' />} />
        <Route path="/amagasaki" element={<PortfolioSite targetPage='amagasaki' />} />
        <Route path="/3pmmm" element={<PortfolioSite targetPage='3pmmm' />} />
        <Route path="/52hz" element={<PortfolioSite targetPage='52hz' />} />
        <Route path="/chapters" element={<PortfolioSite targetPage='chapters' />} />
        <Route path="/*" element={<PortfolioSite targetPage='' />} />
      </Routes>
    </BrowserRouter>
  )
}


export default App;
