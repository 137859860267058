import React from 'react'
import { PopupBody, PopupBodySubHeadline } from './PopupBase'

type Props = {
  fontResizeRate: number;
}

const PopupFlyerOnWall: React.FC<Props> = ({ fontResizeRate }) => {
  return (
    <div>
      <PopupBody fontResizeRate={fontResizeRate}>
        自然の中で開催されるこども向けのイベント「尼崎の森間学校」のフライヤーを制作しました。夕暮れから夜の空を想起させるグラデーションの中、尼崎の森間学校で遊ぶこどもたちをイメージしたタイポグラフィが主役です。「間」は「なにか」を発見した顔、「学」は自由気ままに過ごす笑顔、「校」は元気に森を駆け回る様子を表現しています。いつもの遊びとはひと味異なる、森の中で遊び学ぶわくわくした時間が伝わるデザインを目指しています。
        <br /><br />
        Client：株式会社地域環境計画研究所, 株式会社ここにある
      </PopupBody>
    </div>
  )
}

export default PopupFlyerOnWall