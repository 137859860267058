import React, { useState, useEffect } from 'react'
import Toggle from './Toggle'
import Logo from './Logo'

type Props = {
  headerHeight: number;
  alignment: string;
  setAlignment: React.Dispatch<React.SetStateAction<string>>;
  setFirstViewFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: React.FC<Props> = ({ headerHeight, alignment, setAlignment, setFirstViewFlag }) => {
  const logoHeight = headerHeight
  const [logoWidth, setLogoWidth] = useState(logoHeight * 186.79761 / 121.59191)

  useEffect(() => {
    setLogoWidth(logoHeight * 186.79761 / 121.59191);
  }, [])
  return (
    <div style={{ width: "100vw", height: headerHeight + 20 }}>
      <div style={{ position: "fixed", top: "10px", left: "10px", height: logoHeight, pointerEvents: "none" }}>
        <Logo />
      </div>
      <Toggle
        style={{ top: logoHeight * 0.4 + 10, right: logoHeight * 0.3, height: logoHeight * 4 / 11 }}
        logoWidth={logoWidth}
        logoHeight={logoHeight}
        alignment={alignment}
        setAlignment={setAlignment}
        setFirstViewFlag={setFirstViewFlag}
      />
    </div>
  )
}

export default Header