import React from 'react'
import { PopupBody, PopupBodySubHeadline } from './PopupBase'

type Props = {
  fontResizeRate: number;
}

const PopupPoster: React.FC<Props> = ({ fontResizeRate }) => {
  return (
    <div>
      <PopupBody fontResizeRate={fontResizeRate}>
        snaq.meのこだわりおやつと一緒に届くポスター型の小冊子「3PMmm...」のISSUE.37, 38, 39, 46, 47,48の紙面デザインを担当しました。
      </PopupBody>

      <PopupBodySubHeadline fontResizeRate={fontResizeRate}>
        毎号ことなる、彩りさまざま
      </PopupBodySubHeadline>

      <PopupBody fontResizeRate={fontResizeRate}>
        「snaq.meを通して、あなたにとっての最高なおやつ時間を”mmm...”と考えながら、同時に”mmm...”と味わってもらいたい」。表紙に記されている3PMmm...のことばを軸に「よりよいおやつ時間ってなんだろう」と自分自身のおやつ時間にも心を寄せて制作を進めていきました。
        <br /><br />
        最も大切にしていたのは、毎号の印象をがらりと変えること。編集担当の山越さんをはじめ3PMmm...編集部の皆さまと相談を重ね、それぞれ異なる新鮮味のある楽しさを味わっていただけるようなデザインを目指しました。
        <br /><br />
        例えば画像1,2枚目のISSUE.46は、snaq.me7周年ならではの企画が盛り沢山の内容と、お届けするBOXデザインの雰囲気に合わせてお祝いムードたっぷりに。表紙にはおいしそうな7周年ケーキに誘われたかのように、snaq.me看板キャラクターのりっすの後ろ姿もあります。スナックミーあるあるのコーナーは、Instagramで募集したユーザーさまの「スナックミーあるある」に対して、snaq.meの皆さまのコメントを吹き出し形式で掲載し会話しているように見えるデザインです。（山越さんの素敵アイデアでした）
        <br /><br />
        画像5枚目、1番右のISSUE.37の表紙は1杯のドリンクを気ままに過ごせるプールに見立てた涼しげな装いで、内側の特集ページ（画像3枚目左）にあるドリンクのアレンジレシピとリンクしています。それぞれのプールで思い思いの時間を過ごす人々を描きました。紙面全体として初夏の爽やかな雰囲気の中、ドリンクのひんやりさやしゅわしゅわ感、香りのよさを表現しています。
        <br /><br />
        画像3枚目（中央）ISSUE.38の特集ページでは、テーマ「ゆるっとご自愛」を元にイラストを制作しその中にコラムを入れ込むかたちへデザインしています。残暑の厳しい期間にお届けする号のため、こころ落ち着くリラックス感のある雰囲気を目指しました。
        <br /><br />
        他にも「おやつとHAPPYの関係性」をテーマにインタビューや調査結果、snaq.meで働く皆さまがおやつ瓶をつくってみるISSUE.39や、所属タレントりっすの特集でとにかくりっすまみれのISSUE.47。春先のお出かけしたくなる時期に「おやつピクニック」を提案するISSUE.48。各号さまざまな視点から、おやつ時間を彩るデザインを制作させていただきました。
        <br /><br />
        Client：株式会社スナックミー
        <br />
        Editor：山越 栞
        <br />
        Photo：森田晃博（ISSUE.37,38,39,46）
      </PopupBody>


    </div>
  )
}

export default PopupPoster