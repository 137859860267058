import React from 'react'
import { PopupBody, PopupBodySubHeadline } from './PopupBase'

type Props = {
  fontResizeRate: number;
}

const PopupInvitation: React.FC<Props> = ({ fontResizeRate }) => {
  return (
    <div>
      <PopupBody fontResizeRate={fontResizeRate}>
        {"特別な受注会をお届けするための、品のある招待状とSNS告知用のキービジュアルのデザイン。大小様々な石がバランスをとって積み重なるストーンバランシングと内から外へ広がっていく波を組み合わせたビジュアルが主役です。\n\nストーンバランシングの石同士の重なりは、錦山窯と隣り合う皆さまの繋がりが重なる様子を表現。それらが巧みに連なり、さらに大きく広がっていきますようにと願いを込めました。神秘的で洗練されたニュアンスを軸に、形の異なる様々な波が広がる様子から高揚感が伝わることを目指しています。\n\n実際に錦山窯さんを訪れた際に感じた、目の前の器や事柄にまっすぐ向き合えるような静謐な空気、お話をお伺いさせていただいた際のこだわりと情熱に心を寄せながら制作したデザインです。"}
      </PopupBody>
    </div>
  )
}

export default PopupInvitation