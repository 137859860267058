export const isSmartPhone = () => {
  if (navigator.userAgent.match(/iPhone|iPad|Android.+Mobile/)
    // iPadのuserAgentはなぜかMac OSを名乗ってくるので、ontouchstart
    // が存在するかどうかで判別
    || (/Mac OS/.test(navigator.userAgent) && document.ontouchstart !== undefined)
    || (/CrKey/.test(navigator.userAgent) && document.ontouchstart !== undefined)
  ) {
    return true;
  } else {
    return false;
  }
}

export const isAndroid = () => {
  var ua = navigator.userAgent;
  if (ua.indexOf('Android') > 0) {
    return true
  } else {
    return false
  }
}
