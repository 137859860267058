import React from 'react'
import { map } from '../utils/math'

type Props = {
  height: number | string;
  rotate: number;
  arrowPositionYRate: number;
}

const ReadMore: React.FC<Props> = ({ height, rotate, arrowPositionYRate }) => {
  let arrowPositionYRateFix = arrowPositionYRate
  return (
    <svg
      style={{ height: height, width: "auto", overflow: "visible" }}
      viewBox="0 0 23.64171 22.836329"
      version="1.1"
      id="svg1"
      xmlns="http://www.w3.org/2000/svg">
      <defs
        id="defs1" />
      <g
        id="layer1"
        transform="translate(-92.868744,-143.40416)">
        <g
          id="g224"
          transform="matrix(0.26458333,0,0,0.26458333,-155.15167,-55.549273)">&#10;		<g
            id="g222">&#10;			<circle
              className="st0"
              cx="983.71002"
              cy="795.08002"
              r="36.360001"
              id="circle199"
              style={{ fill: "none" }} />
            &#10;			<path
              transform={`rotate(${rotate},983.71002,795.08002)`}
              d="m 984.68,752.2 0.91,-0.25 h 0.05 l -0.09,1.69 0.01,0.04 -0.28,5.06 -0.96,-0.05 z m 0.17,3.16 0.21,0.01 -0.21,0.89 h -0.09 z m 1.21,-2.54 c 0.23,-0.26 0.49,-0.45 0.77,-0.58 0.28,-0.13 0.58,-0.19 0.89,-0.17 0.19,0.01 0.39,0.05 0.6,0.11 l -0.35,0.77 c -0.21,-0.11 -0.45,-0.18 -0.7,-0.19 -0.24,-0.01 -0.49,0.03 -0.72,0.14 -0.24,0.11 -0.45,0.26 -0.64,0.46 -0.19,0.2 -0.35,0.43 -0.48,0.68 l -0.04,-0.28 c 0.21,-0.37 0.43,-0.68 0.67,-0.94 z"
              id="path199" />
            &#10;			<path
              transform={`rotate(${rotate},983.71002,795.08002)`}
              d="m 989.96,758.94 c -0.4,-0.37 -0.67,-0.84 -0.83,-1.4 -0.15,-0.56 -0.16,-1.17 -0.02,-1.82 0.15,-0.69 0.42,-1.27 0.81,-1.74 0.39,-0.47 0.87,-0.8 1.42,-0.98 0.55,-0.19 1.14,-0.22 1.76,-0.08 0.55,0.12 1,0.34 1.35,0.66 0.35,0.32 0.57,0.73 0.68,1.22 0.11,0.49 0.09,1.02 -0.04,1.61 l -5.27,-1.12 0.04,-0.2 4.82,1.03 -0.51,-0.04 c 0.13,-0.51 0.16,-0.98 0.11,-1.41 -0.05,-0.43 -0.2,-0.78 -0.42,-1.06 -0.23,-0.28 -0.52,-0.45 -0.88,-0.53 -0.41,-0.09 -0.81,-0.04 -1.19,0.15 -0.38,0.19 -0.71,0.5 -0.99,0.92 -0.28,0.42 -0.48,0.93 -0.61,1.51 -0.14,0.66 -0.16,1.25 -0.05,1.77 0.1,0.52 0.31,0.94 0.62,1.26 0.31,0.32 0.69,0.53 1.13,0.63 0.4,0.09 0.83,0.07 1.29,-0.06 0.46,-0.13 0.85,-0.35 1.2,-0.69 l 0.13,0.12 c -0.44,0.45 -0.92,0.76 -1.45,0.93 -0.53,0.17 -1.07,0.2 -1.63,0.08 -0.58,-0.13 -1.07,-0.38 -1.47,-0.76 z"
              id="path200" />
            &#10;			<path
              transform={`rotate(${rotate},983.71002,795.08002)`}
              d="m 995.39,760.24 c -0.2,-0.38 -0.2,-0.79 -0.03,-1.21 0.22,-0.54 0.63,-0.87 1.24,-1.01 0.61,-0.13 1.34,-0.08 2.21,0.16 0.25,0.08 0.74,0.23 1.47,0.47 l -0.07,0.16 -0.13,-0.04 c -0.7,-0.21 -1.28,-0.35 -1.76,-0.42 -0.48,-0.07 -0.89,-0.03 -1.24,0.09 -0.35,0.13 -0.6,0.38 -0.76,0.76 -0.16,0.4 -0.17,0.75 -0.02,1.06 0.15,0.31 0.4,0.53 0.76,0.68 0.32,0.13 0.68,0.18 1.08,0.16 0.4,-0.02 0.79,-0.14 1.18,-0.34 l 0.07,0.17 c -0.47,0.25 -0.97,0.39 -1.51,0.42 -0.54,0.02 -1.03,-0.05 -1.48,-0.24 -0.48,-0.2 -0.82,-0.49 -1.01,-0.87 z m 3.53,1.46 c -0.01,-0.26 0.03,-0.52 0.13,-0.76 l 1.26,-3.06 c 0.18,-0.43 0.27,-0.8 0.29,-1.13 0.01,-0.32 -0.06,-0.61 -0.22,-0.85 -0.16,-0.25 -0.43,-0.44 -0.81,-0.6 -0.32,-0.13 -0.64,-0.2 -0.97,-0.2 -0.33,0 -0.69,0.06 -1.08,0.19 l -0.05,-0.17 c 0.46,-0.17 0.9,-0.27 1.31,-0.28 0.41,-0.01 0.84,0.07 1.29,0.26 0.69,0.28 1.13,0.67 1.33,1.18 0.2,0.5 0.14,1.13 -0.16,1.87 l -1.23,2.99 c -0.09,0.23 -0.15,0.4 -0.18,0.53 -0.03,0.12 -0.02,0.24 0.04,0.34 0.05,0.1 0.18,0.19 0.36,0.27 0.05,0.02 0.12,0.04 0.22,0.06 l -0.03,0.07 c -0.15,0.02 -0.3,0.03 -0.47,0.02 -0.17,-0.01 -0.33,-0.04 -0.48,-0.11 -0.35,-0.15 -0.54,-0.36 -0.55,-0.62 z m 1.36,-2.76 0.25,0.1 -0.49,0.77 -0.13,-0.05 z"
              id="path201" />
            &#10;			<path
              transform={`rotate(${rotate},983.71002,795.08002)`}
              d="m 1002.1,763.33 c -0.21,-0.48 -0.28,-1 -0.2,-1.57 0.08,-0.57 0.29,-1.13 0.64,-1.67 0.38,-0.59 0.84,-1.03 1.38,-1.33 0.54,-0.3 1.1,-0.44 1.67,-0.43 0.57,0.02 1.1,0.18 1.59,0.5 0.42,0.28 0.75,0.63 0.97,1.05 0.22,0.43 0.33,0.9 0.33,1.4 l -0.48,-0.16 c 0.07,-0.43 0.02,-0.83 -0.13,-1.2 -0.15,-0.37 -0.4,-0.67 -0.74,-0.89 -0.37,-0.24 -0.77,-0.34 -1.2,-0.29 -0.43,0.04 -0.86,0.22 -1.28,0.52 -0.42,0.31 -0.82,0.74 -1.18,1.3 -0.36,0.55 -0.59,1.07 -0.69,1.56 -0.1,0.49 -0.09,0.93 0.05,1.31 0.14,0.38 0.37,0.68 0.71,0.9 0.22,0.14 0.46,0.24 0.74,0.31 0.28,0.07 0.56,0.08 0.88,0.05 0.31,-0.03 0.63,-0.1 0.94,-0.23 l 0.07,0.2 c -0.37,0.14 -0.74,0.23 -1.1,0.27 -0.36,0.04 -0.71,0.03 -1.04,-0.04 -0.33,-0.07 -0.65,-0.2 -0.95,-0.4 -0.44,-0.3 -0.77,-0.69 -0.98,-1.16 z m 3.53,1.44 4.86,-7.49 0.94,0.22 0.04,0.03 -4.96,7.64 -0.28,0.94 -0.94,-0.23 z m 1.58,-1.79 0.22,0.14 -0.63,0.66 -0.12,-0.08 z"
              id="path202" />
            &#10;			<path
              transform={`rotate(${rotate},983.71002,795.08002)`}
              d="m 1014.4,764.75 0.8,0.53 0.03,0.04 -5.36,4.19 -0.59,-0.76 z m -2.25,2.23 0.16,0.21 -0.81,0.42 -0.08,-0.11 z m 4.33,1.54 c 0.21,-0.4 0.1,-0.87 -0.32,-1.4 -0.17,-0.21 -0.35,-0.39 -0.56,-0.52 -0.21,-0.13 -0.45,-0.24 -0.73,-0.32 -0.28,-0.08 -0.61,-0.13 -0.99,-0.17 l 0.02,-0.18 c 0.46,0.05 0.85,0.12 1.17,0.2 0.32,0.08 0.63,0.21 0.9,0.38 0.27,0.17 0.53,0.4 0.76,0.69 0.43,0.55 0.6,1.09 0.51,1.61 -0.09,0.52 -0.44,1.03 -1.07,1.52 l -3.52,2.75 -0.59,-0.76 3.5,-2.73 c 0.41,-0.31 0.72,-0.67 0.92,-1.07 z m -1.54,2.03 0.16,0.21 -0.81,0.42 -0.09,-0.11 z m 4.33,1.54 c 0.21,-0.4 0.1,-0.87 -0.32,-1.4 -0.16,-0.21 -0.34,-0.38 -0.55,-0.51 -0.21,-0.13 -0.45,-0.24 -0.73,-0.32 -0.28,-0.08 -0.61,-0.14 -0.99,-0.17 l 0.01,-0.2 c 0.45,0.04 0.84,0.11 1.17,0.2 0.33,0.09 0.64,0.22 0.92,0.39 0.28,0.17 0.53,0.4 0.76,0.7 0.43,0.54 0.59,1.08 0.51,1.6 -0.09,0.52 -0.44,1.03 -1.07,1.52 l -3.52,2.75 -0.59,-0.76 3.5,-2.73 c 0.39,-0.31 0.7,-0.67 0.9,-1.07 z"
              id="path203" />
            &#10;			<path
              transform={`rotate(${rotate},983.71002,795.08002)`}
              d="m 1016.63,778.92 c 0.06,-0.56 0.27,-1.08 0.64,-1.54 0.37,-0.46 0.87,-0.83 1.52,-1.11 0.64,-0.28 1.26,-0.39 1.87,-0.33 0.6,0.06 1.14,0.26 1.62,0.61 0.47,0.35 0.84,0.82 1.09,1.41 0.25,0.58 0.35,1.15 0.29,1.71 -0.06,0.56 -0.27,1.07 -0.64,1.54 -0.37,0.47 -0.87,0.84 -1.51,1.12 -0.64,0.28 -1.27,0.39 -1.87,0.33 -0.6,-0.06 -1.14,-0.26 -1.62,-0.61 -0.47,-0.35 -0.84,-0.82 -1.09,-1.4 -0.26,-0.59 -0.35,-1.16 -0.3,-1.73 z m 1.31,2.6 c 0.38,0.21 0.84,0.3 1.37,0.28 0.53,-0.02 1.1,-0.16 1.7,-0.42 0.61,-0.27 1.11,-0.59 1.5,-0.97 0.39,-0.38 0.64,-0.79 0.75,-1.22 0.12,-0.43 0.09,-0.85 -0.09,-1.25 -0.17,-0.4 -0.45,-0.7 -0.83,-0.91 -0.38,-0.2 -0.84,-0.29 -1.37,-0.27 -0.53,0.02 -1.09,0.16 -1.7,0.43 -0.61,0.26 -1.11,0.59 -1.5,0.97 -0.39,0.38 -0.64,0.78 -0.76,1.21 -0.12,0.43 -0.09,0.84 0.08,1.25 0.19,0.4 0.47,0.7 0.85,0.9 z"
              id="path204" />
            &#10;			<path
              transform={`rotate(${rotate},983.71002,795.08002)`}
              d="m 1024.93,783.2 0.51,0.8 0.01,0.05 -1.64,0.41 -0.03,0.02 -4.92,1.23 -0.23,-0.93 z m -2.97,1.11 0.05,0.21 -0.91,0.06 -0.02,-0.09 z m 2.78,0.39 c 0.31,0.15 0.58,0.34 0.79,0.57 0.21,0.23 0.35,0.5 0.43,0.8 0.05,0.19 0.07,0.39 0.07,0.61 l -0.83,-0.1 c 0.04,-0.24 0.04,-0.48 -0.03,-0.72 -0.06,-0.24 -0.18,-0.45 -0.35,-0.65 -0.17,-0.19 -0.39,-0.35 -0.63,-0.47 -0.24,-0.12 -0.51,-0.21 -0.79,-0.26 l 0.26,-0.12 c 0.4,0.08 0.77,0.2 1.08,0.34 z"
              id="path205" />
            &#10;			<path
              transform={`rotate(${rotate},983.71002,795.08002)`}
              d="m 1020.05,790.24 c 0.24,-0.49 0.6,-0.89 1.1,-1.2 0.5,-0.31 1.07,-0.5 1.73,-0.56 0.71,-0.06 1.34,0.02 1.9,0.26 0.56,0.24 1.02,0.59 1.36,1.06 0.34,0.47 0.54,1.02 0.6,1.65 0.05,0.56 -0.03,1.06 -0.23,1.48 -0.21,0.42 -0.53,0.76 -0.96,1.01 -0.43,0.25 -0.95,0.39 -1.55,0.44 l -0.49,-5.36 0.2,-0.02 0.45,4.91 -0.11,-0.5 c 0.52,-0.03 0.98,-0.14 1.38,-0.32 0.39,-0.18 0.69,-0.42 0.89,-0.72 0.2,-0.3 0.28,-0.63 0.25,-1 -0.04,-0.42 -0.21,-0.78 -0.5,-1.09 -0.29,-0.31 -0.69,-0.53 -1.17,-0.67 -0.48,-0.14 -1.03,-0.19 -1.62,-0.13 -0.67,0.06 -1.24,0.22 -1.7,0.47 -0.46,0.25 -0.8,0.58 -1.02,0.97 -0.22,0.39 -0.31,0.82 -0.27,1.27 0.04,0.41 0.18,0.82 0.43,1.21 0.25,0.39 0.59,0.71 1.02,0.94 l -0.07,0.16 c -0.56,-0.29 -1,-0.66 -1.32,-1.11 -0.32,-0.45 -0.51,-0.97 -0.56,-1.54 -0.07,-0.57 0.03,-1.11 0.26,-1.61 z"
              id="path206" />
            &#10;			<path
              transform={`rotate(${rotate},983.71002,795.08002)`}
              d="m 1022.79,812.75 -0.13,0.94 -0.02,0.05 -1.52,-0.75 -0.04,-0.01 -4.54,-2.25 0.43,-0.86 z m -2.98,-1.08 -0.1,0.19 -0.73,-0.55 0.04,-0.08 z m 1.87,2.11 c 0.14,0.32 0.22,0.63 0.23,0.94 0.01,0.31 -0.05,0.61 -0.19,0.89 -0.09,0.17 -0.2,0.34 -0.34,0.51 l -0.57,-0.62 c 0.19,-0.15 0.34,-0.34 0.45,-0.57 0.11,-0.22 0.16,-0.46 0.15,-0.72 -0.01,-0.26 -0.07,-0.52 -0.18,-0.77 -0.11,-0.25 -0.26,-0.49 -0.44,-0.71 l 0.27,0.08 c 0.27,0.33 0.47,0.65 0.62,0.97 z"
              id="path207" />
            &#10;			<path
              transform={`rotate(${rotate},983.71002,795.08002)`}
              d="m 1014.52,814.95 c 0.5,-0.22 1.04,-0.29 1.62,-0.21 0.58,0.08 1.14,0.31 1.68,0.7 0.58,0.41 1.01,0.88 1.28,1.43 0.28,0.54 0.39,1.11 0.35,1.69 -0.04,0.58 -0.25,1.13 -0.61,1.65 -0.33,0.46 -0.71,0.79 -1.14,0.98 -0.43,0.19 -0.89,0.24 -1.39,0.15 -0.5,-0.09 -0.98,-0.32 -1.46,-0.67 l 3.1,-4.4 0.17,0.12 -2.84,4.03 0.24,-0.45 c 0.42,0.31 0.84,0.53 1.25,0.65 0.42,0.12 0.8,0.13 1.14,0.03 0.34,-0.1 0.62,-0.3 0.83,-0.6 0.24,-0.34 0.35,-0.73 0.33,-1.15 -0.03,-0.43 -0.18,-0.85 -0.46,-1.27 -0.28,-0.42 -0.66,-0.81 -1.15,-1.15 -0.55,-0.39 -1.09,-0.64 -1.6,-0.74 -0.51,-0.1 -0.99,-0.08 -1.41,0.08 -0.42,0.16 -0.76,0.42 -1.02,0.79 -0.24,0.34 -0.39,0.74 -0.45,1.2 -0.06,0.46 -0.01,0.92 0.17,1.38 l -0.16,0.07 c -0.24,-0.58 -0.33,-1.15 -0.29,-1.7 0.05,-0.55 0.24,-1.06 0.57,-1.53 0.33,-0.51 0.75,-0.86 1.25,-1.08 z"
              id="path208" />
            &#10;			<path
              transform={`rotate(${rotate},983.71002,795.08002)`}
              d="m 1008.47,822.12 c 0.25,0.09 0.46,0.23 0.65,0.42 l 2.33,2.36 c 0.33,0.33 0.63,0.56 0.93,0.7 0.29,0.14 0.58,0.18 0.87,0.13 0.29,-0.06 0.58,-0.22 0.87,-0.51 0.25,-0.24 0.43,-0.51 0.56,-0.81 0.13,-0.3 0.21,-0.66 0.24,-1.07 l 0.18,0.02 c -0.02,0.49 -0.11,0.93 -0.25,1.32 -0.14,0.39 -0.4,0.75 -0.74,1.09 -0.53,0.52 -1.06,0.78 -1.6,0.76 -0.54,-0.01 -1.09,-0.31 -1.66,-0.88 l -2.28,-2.3 c -0.17,-0.17 -0.31,-0.3 -0.41,-0.37 -0.1,-0.08 -0.21,-0.11 -0.33,-0.1 -0.12,0.01 -0.24,0.09 -0.39,0.23 -0.04,0.04 -0.08,0.09 -0.14,0.18 l -0.05,-0.05 c 0.04,-0.14 0.09,-0.29 0.16,-0.44 0.07,-0.15 0.17,-0.28 0.29,-0.4 0.27,-0.28 0.53,-0.37 0.77,-0.28 z m 2.73,-2.68 c 0.43,-0.03 0.8,0.12 1.13,0.45 0.41,0.41 0.56,0.93 0.44,1.54 -0.11,0.61 -0.45,1.27 -1.01,1.97 -0.17,0.2 -0.5,0.59 -1.01,1.17 l -0.12,-0.13 0.09,-0.11 c 0.47,-0.56 0.83,-1.04 1.07,-1.46 0.25,-0.42 0.38,-0.81 0.4,-1.18 0.02,-0.37 -0.12,-0.7 -0.41,-1 -0.3,-0.31 -0.62,-0.45 -0.96,-0.43 -0.34,0.02 -0.65,0.16 -0.92,0.43 -0.25,0.24 -0.44,0.55 -0.57,0.94 -0.13,0.38 -0.18,0.79 -0.15,1.22 H 1009 c -0.05,-0.53 0.02,-1.05 0.21,-1.55 0.19,-0.51 0.45,-0.93 0.79,-1.27 0.37,-0.37 0.77,-0.56 1.2,-0.59 z m -0.71,5 -0.19,0.19 -0.51,-0.75 0.1,-0.1 z"
              id="path209" />
            &#10;			<path
              transform={`rotate(${rotate},983.71002,795.08002)`}
              d="m 1003.01,827.11 5,7.4 -0.57,0.78 -0.04,0.03 -5.1,-7.54 -0.75,-0.62 0.58,-0.77 z m 2.71,-2.68 c 0.52,-0.01 1.03,0.14 1.52,0.43 0.49,0.29 0.93,0.7 1.29,1.24 0.39,0.58 0.62,1.18 0.69,1.8 0.07,0.62 -0.02,1.19 -0.26,1.71 -0.24,0.52 -0.6,0.95 -1.08,1.27 -0.42,0.28 -0.87,0.44 -1.35,0.48 -0.48,0.04 -0.95,-0.04 -1.42,-0.25 l 0.34,-0.38 c 0.37,0.23 0.75,0.35 1.15,0.35 0.4,0 0.77,-0.11 1.1,-0.33 0.36,-0.25 0.61,-0.57 0.74,-0.99 0.13,-0.41 0.13,-0.87 0.02,-1.39 -0.11,-0.52 -0.36,-1.04 -0.73,-1.59 -0.37,-0.54 -0.76,-0.96 -1.17,-1.25 -0.41,-0.29 -0.82,-0.44 -1.22,-0.46 -0.4,-0.02 -0.77,0.08 -1.11,0.3 -0.22,0.15 -0.41,0.33 -0.57,0.56 -0.16,0.23 -0.29,0.49 -0.39,0.79 -0.1,0.3 -0.15,0.62 -0.15,0.96 l -0.21,-0.01 c 0.02,-0.4 0.07,-0.77 0.17,-1.12 0.1,-0.35 0.25,-0.66 0.45,-0.94 0.2,-0.28 0.44,-0.52 0.74,-0.72 0.44,-0.3 0.93,-0.46 1.45,-0.46 z m -1.68,4.84 -0.22,0.15 -0.37,-0.84 0.12,-0.08 z"
              id="path210" />
            &#10;			<path
              transform={`rotate(${rotate},983.71002,795.08002)`}
              d="m 990.96,836.8 c 0.28,0.35 0.76,0.43 1.42,0.26 0.25,-0.07 0.48,-0.17 0.68,-0.31 0.2,-0.14 0.4,-0.32 0.58,-0.55 0.18,-0.23 0.37,-0.51 0.55,-0.85 l 0.18,0.08 c -0.22,0.4 -0.43,0.73 -0.64,1 -0.21,0.27 -0.45,0.5 -0.72,0.69 -0.27,0.19 -0.58,0.33 -0.94,0.43 -0.67,0.18 -1.23,0.12 -1.67,-0.16 -0.45,-0.29 -0.77,-0.81 -0.98,-1.58 l -1.15,-4.31 0.93,-0.25 1.15,4.29 c 0.12,0.49 0.33,0.91 0.61,1.26 z m 4.38,-1.17 c 0.29,0.35 0.76,0.43 1.41,0.26 0.26,-0.07 0.5,-0.17 0.7,-0.31 0.21,-0.14 0.4,-0.32 0.58,-0.55 0.18,-0.23 0.36,-0.51 0.54,-0.84 l 0.15,0.09 c -0.22,0.4 -0.44,0.73 -0.64,1 -0.2,0.27 -0.44,0.5 -0.7,0.68 -0.26,0.18 -0.58,0.33 -0.93,0.43 -0.68,0.18 -1.24,0.13 -1.68,-0.16 -0.45,-0.29 -0.77,-0.81 -0.98,-1.58 l -1.15,-4.31 0.93,-0.25 1.15,4.29 c 0.12,0.48 0.33,0.9 0.62,1.25 z m -1.26,-2.21 -0.26,0.07 -0.07,-0.91 0.13,-0.04 z m 5.55,1.77 -0.8,0.53 -0.05,0.01 -1.76,-6.57 0.93,-0.25 z m -1.17,-2.94 -0.26,0.07 -0.07,-0.91 0.13,-0.04 z"
              id="path211" />
            &#10;			<path
              transform={`rotate(${rotate},983.71002,795.08002)`}
              d="m 985.74,831.67 c 0.5,0.27 0.89,0.66 1.18,1.18 0.29,0.52 0.44,1.13 0.45,1.83 0.01,0.7 -0.12,1.32 -0.41,1.85 -0.29,0.53 -0.68,0.96 -1.18,1.26 -0.5,0.3 -1.08,0.46 -1.72,0.47 -0.63,0.01 -1.2,-0.12 -1.69,-0.39 -0.49,-0.27 -0.89,-0.66 -1.18,-1.18 -0.29,-0.52 -0.44,-1.13 -0.45,-1.83 -0.01,-0.7 0.13,-1.32 0.41,-1.85 0.29,-0.54 0.68,-0.96 1.19,-1.26 0.51,-0.3 1.07,-0.46 1.71,-0.47 0.62,-0.01 1.19,0.12 1.69,0.39 z m -2.91,0.21 c -0.34,0.28 -0.6,0.66 -0.78,1.16 -0.19,0.5 -0.27,1.07 -0.26,1.73 0.01,0.67 0.12,1.25 0.32,1.75 0.2,0.5 0.48,0.89 0.83,1.16 0.35,0.27 0.75,0.41 1.19,0.4 0.44,-0.01 0.82,-0.15 1.16,-0.42 0.33,-0.28 0.59,-0.66 0.77,-1.16 0.18,-0.5 0.27,-1.07 0.26,-1.73 -0.01,-0.67 -0.12,-1.25 -0.32,-1.75 -0.2,-0.5 -0.48,-0.89 -0.83,-1.16 -0.35,-0.27 -0.74,-0.41 -1.18,-0.4 -0.44,0.01 -0.82,0.15 -1.16,0.42 z"
              id="path212" />
            &#10;			<path
              transform={`rotate(${rotate},983.71002,795.08002)`}
              d="m 977.19,836.89 c -0.26,0.23 -0.53,0.4 -0.83,0.5 -0.29,0.1 -0.59,0.13 -0.9,0.08 -0.19,-0.03 -0.39,-0.09 -0.59,-0.17 l 0.42,-0.73 c 0.2,0.13 0.43,0.22 0.68,0.26 0.24,0.04 0.49,0.01 0.73,-0.07 0.25,-0.09 0.47,-0.22 0.68,-0.4 0.21,-0.18 0.39,-0.39 0.55,-0.63 l 0.01,0.28 c -0.24,0.36 -0.49,0.65 -0.75,0.88 z m 1.32,0.76 -0.93,0.16 -0.05,-0.01 0.26,-1.67 -0.01,-0.04 0.78,-5.01 0.95,0.15 z m 0.14,-3.17 -0.21,-0.03 0.3,-0.86 0.09,0.01 z"
              id="path213" />
            &#10;			<path
              transform={`rotate(${rotate},983.71002,795.08002)`}
              d="m 973.94,830.42 c 0.36,0.41 0.59,0.9 0.68,1.48 0.1,0.57 0.04,1.18 -0.16,1.81 -0.22,0.67 -0.54,1.23 -0.98,1.65 -0.44,0.43 -0.94,0.71 -1.51,0.84 -0.57,0.13 -1.15,0.1 -1.76,-0.09 -0.54,-0.17 -0.96,-0.44 -1.27,-0.79 -0.31,-0.36 -0.5,-0.78 -0.55,-1.28 -0.06,-0.5 0.01,-1.03 0.2,-1.6 l 5.13,1.64 -0.06,0.19 -4.7,-1.5 0.5,0.09 c -0.18,0.49 -0.26,0.96 -0.25,1.39 0.01,0.43 0.12,0.8 0.31,1.1 0.2,0.3 0.47,0.5 0.83,0.62 0.4,0.13 0.8,0.12 1.2,-0.03 0.4,-0.15 0.76,-0.42 1.08,-0.82 0.32,-0.39 0.57,-0.88 0.75,-1.45 0.21,-0.64 0.28,-1.23 0.23,-1.75 -0.05,-0.53 -0.22,-0.97 -0.5,-1.32 -0.28,-0.35 -0.63,-0.6 -1.06,-0.74 -0.39,-0.13 -0.82,-0.15 -1.29,-0.07 -0.47,0.08 -0.88,0.27 -1.26,0.57 l -0.12,-0.13 c 0.48,-0.4 1,-0.66 1.54,-0.78 0.54,-0.12 1.09,-0.09 1.63,0.08 0.57,0.19 1.03,0.48 1.39,0.89 z"
              id="path214" />
            &#10;			<path
              transform={`rotate(${rotate},983.71002,795.08002)`}
              d="m 951.67,822.72 c -0.35,0.01 -0.67,-0.04 -0.96,-0.15 -0.29,-0.11 -0.54,-0.29 -0.74,-0.52 -0.13,-0.15 -0.24,-0.32 -0.34,-0.51 l 0.79,-0.28 c 0.07,0.23 0.18,0.44 0.35,0.64 0.16,0.19 0.36,0.33 0.6,0.42 0.24,0.1 0.5,0.14 0.78,0.14 0.28,0 0.55,-0.05 0.83,-0.13 l -0.18,0.22 c -0.41,0.1 -0.79,0.16 -1.13,0.17 z m 0.51,1.43 -0.82,-0.48 -0.03,-0.04 1.28,-1.11 0.02,-0.03 3.84,-3.31 0.63,0.73 z m 2.15,-2.33 -0.14,-0.16 0.79,-0.46 0.06,0.07 z"
              id="path215" />
            &#10;			<path
              transform={`rotate(${rotate},983.71002,795.08002)`}
              d="m 953.36,815.65 c 0.01,0.55 -0.14,1.07 -0.44,1.57 -0.3,0.5 -0.73,0.93 -1.3,1.28 -0.6,0.37 -1.21,0.58 -1.82,0.62 -0.61,0.04 -1.17,-0.07 -1.69,-0.34 -0.52,-0.27 -0.95,-0.67 -1.28,-1.21 -0.3,-0.48 -0.45,-0.96 -0.46,-1.43 -0.01,-0.47 0.13,-0.92 0.41,-1.33 0.28,-0.42 0.67,-0.78 1.18,-1.09 l 2.85,4.57 -0.17,0.11 -2.61,-4.19 0.32,0.4 c -0.45,0.26 -0.81,0.56 -1.09,0.9 -0.27,0.34 -0.43,0.68 -0.47,1.04 -0.04,0.35 0.03,0.69 0.23,1 0.22,0.36 0.54,0.61 0.94,0.75 0.4,0.14 0.85,0.17 1.35,0.08 0.5,-0.09 1,-0.3 1.51,-0.61 0.57,-0.36 1.01,-0.75 1.31,-1.19 0.3,-0.44 0.46,-0.88 0.48,-1.33 0.02,-0.45 -0.09,-0.87 -0.33,-1.25 -0.22,-0.35 -0.53,-0.65 -0.93,-0.89 -0.4,-0.24 -0.85,-0.37 -1.33,-0.38 v -0.18 c 0.63,0.01 1.19,0.14 1.68,0.4 0.49,0.26 0.89,0.64 1.19,1.12 0.3,0.51 0.46,1.03 0.47,1.58 z"
              id="path216" />
            &#10;			<path
              transform={`rotate(${rotate},983.71002,795.08002)`}
              d="m 949.12,807.3 c -0.18,0.19 -0.4,0.33 -0.64,0.43 l -3.08,1.22 c -0.43,0.17 -0.77,0.36 -1.01,0.58 -0.24,0.21 -0.39,0.46 -0.46,0.75 -0.06,0.29 -0.02,0.62 0.13,1 0.13,0.32 0.3,0.6 0.53,0.84 0.23,0.24 0.52,0.45 0.89,0.64 l -0.09,0.16 c -0.45,-0.21 -0.82,-0.46 -1.11,-0.75 -0.29,-0.29 -0.53,-0.66 -0.71,-1.11 -0.27,-0.69 -0.3,-1.28 -0.08,-1.77 0.22,-0.49 0.71,-0.89 1.46,-1.19 l 3.01,-1.19 c 0.23,-0.09 0.4,-0.17 0.51,-0.23 0.11,-0.07 0.18,-0.15 0.21,-0.26 0.03,-0.11 0.01,-0.26 -0.06,-0.44 -0.02,-0.05 -0.05,-0.11 -0.11,-0.2 l 0.07,-0.03 c 0.12,0.09 0.23,0.2 0.34,0.32 0.11,0.12 0.19,0.27 0.26,0.42 0.14,0.34 0.12,0.62 -0.06,0.81 z m 1.41,3.56 c -0.14,0.4 -0.42,0.69 -0.85,0.86 -0.54,0.21 -1.07,0.15 -1.59,-0.19 -0.52,-0.34 -0.99,-0.91 -1.42,-1.7 -0.12,-0.23 -0.35,-0.7 -0.69,-1.39 l 0.17,-0.06 0.06,0.12 c 0.33,0.65 0.64,1.17 0.92,1.56 0.28,0.39 0.6,0.67 0.93,0.83 0.33,0.16 0.69,0.17 1.07,0.02 0.4,-0.16 0.66,-0.4 0.78,-0.72 0.12,-0.32 0.11,-0.66 -0.04,-1.02 -0.13,-0.32 -0.34,-0.62 -0.64,-0.89 -0.3,-0.27 -0.65,-0.48 -1.06,-0.61 l 0.07,-0.16 c 0.51,0.16 0.96,0.43 1.35,0.8 0.39,0.37 0.68,0.78 0.85,1.23 0.2,0.47 0.23,0.91 0.09,1.32 z m -4.33,-2.62 -0.1,-0.25 0.9,-0.18 0.05,0.13 z"
              id="path217" />
            &#10;			<path
              transform={`rotate(${rotate},983.71002,795.08002)`}
              d="m 946.68,800.33 -8.77,1.69 -0.5,-0.83 -0.01,-0.05 8.94,-1.73 0.87,-0.45 0.48,0.83 z m 1.4,3.54 c -0.2,0.48 -0.53,0.89 -0.99,1.23 -0.46,0.34 -1.01,0.58 -1.65,0.7 -0.69,0.13 -1.33,0.11 -1.92,-0.07 -0.59,-0.18 -1.08,-0.48 -1.47,-0.91 -0.39,-0.43 -0.64,-0.92 -0.75,-1.49 -0.1,-0.5 -0.07,-0.97 0.08,-1.43 0.15,-0.46 0.41,-0.86 0.78,-1.21 l 0.22,0.46 c -0.36,0.25 -0.61,0.56 -0.77,0.92 -0.16,0.37 -0.2,0.75 -0.13,1.15 0.08,0.43 0.29,0.79 0.62,1.07 0.33,0.28 0.75,0.47 1.27,0.56 0.52,0.09 1.1,0.08 1.75,-0.05 0.65,-0.12 1.18,-0.32 1.61,-0.59 0.43,-0.27 0.73,-0.58 0.91,-0.94 0.18,-0.36 0.23,-0.74 0.15,-1.14 -0.05,-0.26 -0.15,-0.5 -0.29,-0.74 -0.14,-0.24 -0.34,-0.46 -0.57,-0.67 -0.24,-0.21 -0.51,-0.38 -0.82,-0.52 l 0.09,-0.19 c 0.36,0.17 0.68,0.37 0.96,0.6 0.28,0.23 0.51,0.49 0.69,0.78 0.18,0.29 0.3,0.61 0.37,0.96 0.11,0.54 0.06,1.04 -0.14,1.52 z m -3.79,-3.44 -0.05,-0.26 0.91,-0.01 0.03,0.14 z"
              id="path218" />
            &#10;			<path
              transform={`rotate(${rotate},983.71002,795.08002)`}
              d="m 940.57,794.04 -0.17,-0.94 0.01,-0.05 6.74,0.94 -0.13,0.95 z m 1.27,-4.12 c -0.43,0.13 -0.69,0.53 -0.79,1.2 -0.04,0.27 -0.03,0.52 0.01,0.77 0.05,0.25 0.14,0.49 0.28,0.75 0.14,0.25 0.33,0.53 0.57,0.83 l -0.14,0.11 c -0.28,-0.36 -0.51,-0.69 -0.67,-0.98 -0.17,-0.29 -0.29,-0.6 -0.36,-0.91 -0.07,-0.32 -0.08,-0.66 -0.03,-1.03 0.1,-0.69 0.36,-1.19 0.8,-1.49 0.44,-0.3 1.05,-0.4 1.83,-0.29 l 4.42,0.62 -0.13,0.95 -4.4,-0.61 c -0.49,-0.08 -0.96,-0.05 -1.39,0.08 z m 0.63,-4.49 c -0.43,0.13 -0.69,0.53 -0.79,1.21 -0.04,0.26 -0.03,0.51 0.02,0.75 0.05,0.24 0.14,0.49 0.28,0.75 0.14,0.26 0.33,0.54 0.57,0.83 l -0.15,0.13 c -0.28,-0.35 -0.51,-0.68 -0.67,-0.98 -0.17,-0.3 -0.28,-0.61 -0.35,-0.93 -0.07,-0.32 -0.08,-0.66 -0.03,-1.03 0.1,-0.68 0.36,-1.18 0.8,-1.48 0.44,-0.3 1.05,-0.4 1.83,-0.29 l 4.42,0.62 -0.13,0.95 -4.4,-0.62 c -0.5,-0.06 -0.97,-0.03 -1.4,0.09 z m 1.27,8.69 0.04,-0.26 0.87,0.29 -0.02,0.14 z m 0.63,-4.5 0.04,-0.26 0.87,0.29 -0.02,0.14 z"
              id="path219" />
            &#10;			<path
              transform={`rotate(${rotate},983.71002,795.08002)`}
              d="m 949.22,782.6 c -0.45,0.35 -0.96,0.55 -1.55,0.61 -0.59,0.06 -1.21,-0.05 -1.85,-0.32 -0.65,-0.27 -1.16,-0.64 -1.54,-1.11 -0.38,-0.47 -0.61,-1 -0.68,-1.59 -0.08,-0.58 0.01,-1.17 0.25,-1.76 0.24,-0.58 0.59,-1.05 1.03,-1.39 0.44,-0.34 0.96,-0.55 1.55,-0.61 0.59,-0.06 1.21,0.05 1.86,0.31 0.65,0.27 1.16,0.64 1.53,1.12 0.37,0.48 0.6,1.01 0.68,1.59 0.08,0.58 -0.01,1.17 -0.25,1.75 -0.24,0.58 -0.59,1.05 -1.03,1.4 z m 0.96,-2.75 c -0.12,-0.42 -0.37,-0.81 -0.75,-1.18 -0.38,-0.37 -0.88,-0.68 -1.48,-0.93 -0.62,-0.26 -1.19,-0.39 -1.73,-0.41 -0.54,-0.02 -1.01,0.08 -1.4,0.3 -0.39,0.22 -0.67,0.53 -0.84,0.93 -0.17,0.4 -0.19,0.81 -0.07,1.23 0.12,0.42 0.37,0.81 0.75,1.17 0.38,0.36 0.88,0.67 1.48,0.93 0.62,0.26 1.19,0.39 1.73,0.41 0.54,0.02 1.01,-0.08 1.4,-0.29 0.39,-0.21 0.67,-0.52 0.84,-0.92 0.17,-0.41 0.19,-0.82 0.07,-1.24 z"
              id="path220" />
            &#10;			<path
              transform={`rotate(${rotate},983.71002,795.08002)`}
              d="m 946.55,773.65 0.22,-0.92 0.03,-0.04 1.44,0.9 0.04,0.01 4.3,2.69 -0.51,0.82 z m 1.21,-0.91 c -0.11,-0.33 -0.16,-0.65 -0.14,-0.96 0.02,-0.31 0.11,-0.6 0.28,-0.86 0.1,-0.16 0.23,-0.32 0.39,-0.47 l 0.5,0.67 c -0.2,0.13 -0.37,0.31 -0.5,0.52 -0.13,0.21 -0.2,0.44 -0.22,0.7 -0.02,0.26 0.01,0.52 0.1,0.78 0.09,0.26 0.21,0.51 0.37,0.75 l -0.26,-0.1 c -0.24,-0.36 -0.41,-0.7 -0.52,-1.03 z m 1.65,2.28 0.11,-0.18 0.67,0.61 -0.05,0.08 z"
              id="path221" />
            &#10;			<path
              transform={`rotate(${rotate},983.71002,795.08002)`}
              d="m 955.01,772.25 c -0.52,0.17 -1.06,0.19 -1.63,0.05 -0.57,-0.14 -1.1,-0.42 -1.6,-0.86 -0.54,-0.46 -0.92,-0.98 -1.14,-1.55 -0.22,-0.57 -0.28,-1.14 -0.18,-1.72 0.1,-0.58 0.36,-1.1 0.77,-1.58 0.37,-0.43 0.78,-0.71 1.23,-0.86 0.45,-0.15 0.91,-0.15 1.39,-0.01 0.48,0.14 0.94,0.41 1.39,0.81 l -3.52,4.08 -0.15,-0.13 3.22,-3.73 -0.28,0.42 c -0.38,-0.35 -0.78,-0.61 -1.18,-0.77 -0.4,-0.16 -0.78,-0.21 -1.13,-0.14 -0.35,0.06 -0.65,0.24 -0.89,0.52 -0.28,0.32 -0.42,0.69 -0.44,1.12 -0.02,0.43 0.09,0.86 0.33,1.31 0.24,0.45 0.58,0.87 1.03,1.26 0.51,0.44 1.02,0.74 1.52,0.9 0.5,0.16 0.97,0.18 1.41,0.06 0.43,-0.12 0.8,-0.35 1.1,-0.69 0.27,-0.31 0.46,-0.7 0.57,-1.15 0.11,-0.46 0.1,-0.92 -0.03,-1.39 l 0.17,-0.06 c 0.18,0.6 0.22,1.18 0.12,1.72 -0.1,0.54 -0.34,1.04 -0.72,1.47 -0.39,0.45 -0.84,0.75 -1.36,0.92 z"
              id="path222" />
            &#10;		</g>
          &#10;		<rect
            x="982.04999"
            y="810.48999"
            transform="matrix(0.7071,-0.7071,0.7071,0.7071,-286.1577,936.4638)"
            width="3.3299999"
            height="3.3299999"
            id="rect222" />
          &#10;		<g
            id="g223">&#10;			<polyline
              className="st27"
              points={`995.91,${map(arrowPositionYRateFix, 0, 1, 790.26 - 5, 790.26 + 5)} 983.71,${map(arrowPositionYRateFix, 0, 1, 802.46 - 5, 802.46 + 5)} 971.52,${map(arrowPositionYRateFix, 0, 1, 790.26 - 5, 790.26 + 5)}`}
              id="polyline222"
              style={{ fill: "none", stroke: "#222222", strokeMiterlimit: 10 }} />
            &#10;			<line
              className="st27"
              x1="983.71002"
              y1={`${map(arrowPositionYRateFix, 0, 1, 773.09003 - 5, 773.09003 + 5)}`}
              x2="983.71002"
              y2={`${map(arrowPositionYRateFix, 0, 1, 802.46002 - 5, 802.46002 + 5)}`}
              id="line222"
              style={{ fill: "none", stroke: "#222222", strokeMiterlimit: 10 }} />
            &#10;		</g>
          &#10;	</g>
      </g>
    </svg>
  )
}

export default ReadMore