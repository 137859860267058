import React, { useState, useEffect, useRef } from 'react'
import CatAnimation from './CatAnimation';
import Bookshelf from './Furnitures/Bookshelf';
import Chair from './Furnitures/Chair';
import Playlist from './Portfolios/Objects/Playlist';
import Leaflet from './Portfolios/Objects/Leaflet';
import CassetteTape from './Portfolios/Objects/CassetteTape';
import Poster from './Portfolios/Objects/Poster';
import { isSmartPhone } from '../utils/computerTerminal'
import { convertToRange } from '../utils/math';
import Chest from './Furnitures/Chest';
import Mirror from './Portfolios/Objects/Mirror';
import Plant from './Portfolios/Objects/Plant';
import Pendentif from './Portfolios/Objects/Pendentif';
import { useWindowDimensions } from '../utils/windowDimensions'
import ObjectBase from './Portfolios/Objects/ObjectBase';
import Invitation from './Portfolios/Objects/Invitation';
import FlyerInBookshelf from './Portfolios/Objects/FlyerInBookshelf';
import Dish from './Portfolios/Objects/Dish';
import FlyerOnWall from './Portfolios/Objects/FlyerOnWall';
import PhotoOnWall from './Portfolios/Objects/PhotoOnWall';
import CorporateLogo1 from './Portfolios/Objects/CorporateLogo1';
import CorporateLogo2 from './Portfolios/Objects/CorporateLogo2';
import CorporateLogo3 from './Portfolios/Objects/CorporateLogo3';
import { PortfolioType } from '../model/Portfolio';
import { XY } from '../model/XY';
import Slipper from './Furnitures/Slipper';
import EmptyStand from './Furnitures/EmptyStand';

type BookshelfState = {
  width: number;
  bottom: number;
  shelf1Bottom: number;
  shelf2Bottom: number;
  shelfTopBottom: number;
}

type ChairState = {
  width: number;
  bottom: number;
  seatBottom: number;
}

type ChestState = {
  width: number;
  bottom: number;
  chestTopBottom: number;
}

type MirrorState = {
  top: number;
  width: number;
}

type EmptyStandState = {
  bottom: number;
  width: number;
}

type SlipperState = {
  bottom: number;
  width: number;
}

type PlantState = {
  width: number;
  bottom: number;
}

type TouchStartState = {
  xy: XY;
  refPosition: number;
}

type Props = {
  firstViewFlag: boolean;
  setFirstViewFlag: React.Dispatch<React.SetStateAction<boolean>>;
  opacityRate: number;
  furnitureFallRate: number;
  portfolioFallRate: number;
  refPosition: number;
  setRefPosition: React.Dispatch<React.SetStateAction<number>>;
  currentPortfolioType: PortfolioType;
  showPopupBase: boolean;
  setShowPopupBase: React.Dispatch<React.SetStateAction<boolean>>;
  portfolioType: PortfolioType;
  setPortfolioType: React.Dispatch<React.SetStateAction<PortfolioType>>;
}

const RoomView: React.FC<Props> = ({ firstViewFlag, setFirstViewFlag, opacityRate, furnitureFallRate, portfolioFallRate, refPosition, setRefPosition, currentPortfolioType, showPopupBase, setShowPopupBase, portfolioType, setPortfolioType }) => {
  const { width, height } = useWindowDimensions()
  const opacityRateExponentValue = 1.3;
  const bookshelfHeight = height * 0.5;
  const chairHeight = height * 0.4;
  const flyerOnWallHeight = height * 0.21;
  const posterHeight = height * 0.3;
  const photoOnWallHeight = height * 0.15;
  const chestHeight = height * 0.27;
  const mirrorHeight = height * 0.4;
  const emptyStandHeight = height * 0.022;
  const slipperHeight = height * 0.06;
  const corporateLogo1Height = height * 0.16;
  const corporateLogo2Height = height * 0.15;
  const corporateLogo3Height = height * 0.12;
  const plantHeight = height * 0.54;
  const roomElem = document.getElementById('room');
  const isTouchDevice = isSmartPhone();
  const [autoMoveRemainFlag, setAutoMoveRemainFlag] = useState(false);
  const [preTouchedXY, setPreTouchedXY] = useState<XY>({ x: 0, y: 0 });
  const [velocityChangeIndex, setVelocityChangeIndex] = useState(false);
  const [refVelocityXY, setRefVelocityXY] = useState<XY>({ x: 0, y: 0 });
  const [refVelocityRemainFlag, setRefVelocityRemainFlag] = useState(true);
  const [bookshelfLeft, setBookshelfLeft] = useState(refPosition);
  const bookshelfState: BookshelfState = {
    width: bookshelfHeight * (198.87454 / 182.6956),
    bottom: height * 0.1,
    shelf1Bottom: height * 0.1 + bookshelfHeight * 0.235,
    shelf2Bottom: height * 0.1 + bookshelfHeight * 0.585,
    shelfTopBottom: height * 0.1 + bookshelfHeight,
  };
  const [chairLeft, setChairLeft] = useState(bookshelfLeft + bookshelfState.width * 0.99);
  const chairState: ChairState = {
    width: chairHeight * (142.54434 / 202.2307),
    bottom: height * 0.05,
    seatBottom: height * 0.07 + chairHeight * 0.5,
  };
  const [catLeft, setCatLeft] = useState(bookshelfLeft + bookshelfState.width * 1.45);
  const [flyerOnWallLeft, setFlyerOnWallLeft] = useState(bookshelfLeft + bookshelfState.width * 1.14);
  const [posterLeft, setPosterLeft] = useState(bookshelfLeft + bookshelfState.width * 1.48);
  const [photoOnWallLeft, setPhotoOnWallLeft] = useState(bookshelfLeft + bookshelfState.width * 2.33);
  const [chestLeft, setChestLeft] = useState(bookshelfLeft + bookshelfState.width * 2.00);
  const chestState: ChestState = {
    bottom: height * 0.12,
    width: chestHeight * 145.10243 / 144.74667,
    chestTopBottom: height * 0.12 + chestHeight * 0.97,
  }
  const [mirrorLeft, setMirrorLeft] = useState(bookshelfLeft + bookshelfState.width * 2.87);
  const mirrorState: MirrorState = {
    top: height * 0.115,
    width: mirrorHeight * 178.51614 / 233.26427,
  }
  const [emptyStandLeft, setEmptyStandLeft] = useState(bookshelfLeft + bookshelfState.width * 2.87);
  const emptyStandState: EmptyStandState = {
    bottom: height * 0.12,
    width: emptyStandHeight * 361.76 / 26.54,
  }
  const [slipperLeft, setSlipperLeft] = useState(bookshelfLeft + bookshelfState.width * 3.59);
  const slipperState: SlipperState = {
    bottom: height * 0.05,
    width: slipperHeight * 194.54 / 62.3,
  }
  const [corporateLogo1Left, setCorporateLogo1Left] = useState(bookshelfLeft + bookshelfState.width * 3.38);
  const [corporateLogo2Left, setCorporateLogo2Left] = useState(bookshelfLeft + bookshelfState.width * 3.74);
  const [corporateLogo3Left, setCorporateLogo3Left] = useState(bookshelfLeft + bookshelfState.width * 3.44);
  const [plantLeft, setPlantLeft] = useState(bookshelfLeft + bookshelfState.width * 4.207);
  const plantState: PlantState = {
    bottom: height * 0.07,
    width: plantHeight * (366.88 / 513.09),
  };
  const roomWidth = bookshelfState.width * 4.207 + bookshelfState.width / 2 + plantState.width / 2 + bookshelfState.width * 0.2;
  const leftPositionThX = -(roomWidth - width) * 0.5;
  const rightPositionThX = (roomWidth + width) * 0.5;
  const [isTouched, setIsTouched] = useState(false);
  const [touchStartState, setTouchStartState] = useState<TouchStartState>({
    xy: { x: 0, y: 0 },
    refPosition: refPosition,
  });

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchedX = e.touches[0].clientX;
    const touchedY = e.touches[0].clientY;
    onTouchStartFunc(touchedX, touchedY);
  }

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const touchedX = e.pageX;
    const touchedY = e.pageY;
    onTouchStartFunc(touchedX, touchedY);
  }

  const onTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchedX = e.touches[0].clientX;
    const touchedY = e.touches[0].clientY;
    if (isTouched) {
      onMoveFunc(touchedX, touchedY);
    }
  }
  const onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const touchedX = e.pageX;
    const touchedY = e.pageY;
    if (isTouched) {
      // console.log(e)
      onMoveFunc(touchedX, touchedY);
    }
  }

  const onTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchedX = e.changedTouches[0].clientX;
    const touchedY = e.changedTouches[0].clientY;
    onTouchEndFunc(touchedX, touchedY)
  }

  const onMouseUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const touchedX = e.pageX;
    const touchedY = e.pageY;
    onTouchEndFunc(touchedX, touchedY);
  }

  const onMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const touchedX = e.pageX;
    const touchedY = e.pageY;
    setIsTouched(false);
    setRefVelocityRemainFlag(true);
    setPreTouchedXY({ x: touchedX, y: touchedY })
    setTouchStartState({
      xy: touchStartState.xy,
      refPosition: refPosition,
    });
  }

  const onWheel = (e: any) => {
    // console.log("onWheel")
    if (e.ctrlKey) {
      return
    }
    setRefVelocityRemainFlag(false);
    setRefVelocityXY({ x: 0, y: 0 });
    const deltaX = -e.deltaX;
    e.preventDefault();
    setRefPosition(refPosition + deltaX)
    setTouchStartState({
      xy: touchStartState.xy,
      refPosition: refPosition + deltaX,
    });
  }

  const onTouchStartFunc = (touchedX: number, touchedY: number) => {
    // console.log("onTouchStartFunc")
    setIsTouched(true);
    setRefVelocityRemainFlag(false);
    setPreTouchedXY({ x: touchedX, y: touchedY });
    setRefVelocityXY({ x: 0, y: 0 });
    // console.log({x: touchedX, y: touchedY});
    setTouchStartState({
      xy: { x: touchedX, y: touchedY },
      refPosition: touchStartState.refPosition,
    });
  }

  const onMoveFunc = (touchedX: number, touchedY: number) => {
    // console.log("onMoveFunc")
    setPreTouchedXY({ x: touchedX, y: touchedY });

    setRefVelocityXY({ x: touchedX - preTouchedXY.x, y: touchedX - preTouchedXY.y });
    setRefVelocityRemainFlag(false);
    setRefPosition(touchStartState.refPosition + (touchedX - touchStartState.xy.x) * 1);
  }

  const onTouchEndFunc = (touchedX: number, touchedY: number) => {
    // console.log("onTouchEndFunc")
    setIsTouched(false);
    setRefVelocityRemainFlag(true);
    setPreTouchedXY({ x: touchedX, y: touchedY });
    // setRefVelocity(touchedX - preTouchedX)
    setTouchStartState({
      xy: touchStartState.xy,
      refPosition: refPosition,
    });
  }

  useEffect(() => {
    let targetPosition: number;
    if (["dish", "playlist", "invitation", "leaflet", "cassette tape", "flyer in bookshelf"].includes(portfolioType)) {
      // 本棚に移動する
      targetPosition = 0 + width * 0.5;
    }
    else if (portfolioType === "flyer on wall") {
      targetPosition = -bookshelfState.width * 1.14 + width * 0.5
    }
    else if (portfolioType === "poster") {
      targetPosition = -bookshelfState.width * 1.48 + width * 0.5
    }
    else if (portfolioType === "pendentif") {
      targetPosition = -bookshelfState.width * 2.00 + width * 0.5
    }
    else if (portfolioType === "photo on wall") {
      targetPosition = -bookshelfState.width * 2.33 + width * 0.5
    }
    else if (["async studio", "necotto", "vok"].includes(portfolioType)) {
      targetPosition = -bookshelfState.width * 3.5 + width * 0.5
    }
    else if (portfolioType === "mirror") {
      targetPosition = -bookshelfState.width * 2.87 + width * 0.5
    }
    else if (portfolioType === "about me") {
      targetPosition = -bookshelfState.width * 4.207 + width * 0.5
    }
    else {
      return
    }
    if (showPopupBase) {
      let refPosition_ = convertToRange(refPosition, leftPositionThX, rightPositionThX)
      setTimeout(() => {
        let diff = targetPosition - refPosition_;
        if (Math.abs(diff) > roomWidth * 0.5) {
          if (diff > 0) {
            diff = roomWidth - diff
          } else {
            diff = roomWidth + diff
          }
        }
        if (Math.abs(diff) > 1) {
          setAutoMoveRemainFlag(!autoMoveRemainFlag)
          setRefPosition(refPosition_ + diff * 0.05)
        }
      }, 1000 / 60);
      setTouchStartState({
        xy: touchStartState.xy,
        refPosition: refPosition_,
      });
    }
  }, [portfolioType, width, autoMoveRemainFlag, showPopupBase, roomWidth])

  useEffect(() => {
    if (isTouched) {
      setRefVelocityXY({ x: 0, y: 0 });
    }
  }, [isTouched])

  useEffect(() => {
    if (refVelocityRemainFlag) {
      // console.log(`スピード残り.refVelocity: ${refVelocity}`)
      setTimeout(() => {
        let newVelocityX = refVelocityXY.x * 0.94;
        if (Math.abs(newVelocityX) > 1) {
          setRefVelocityXY({ x: newVelocityX, y: refVelocityXY.y })
          setVelocityChangeIndex(!velocityChangeIndex)
        } else {
          setRefVelocityXY({ x: 0, y: 0 });
        }

      }, 1000 / 60);
    }
  }, [refVelocityRemainFlag, velocityChangeIndex])

  useEffect(() => {
    if (refVelocityRemainFlag) {
      setRefPosition(refPosition + refVelocityXY.x)
      setTouchStartState({
        xy: touchStartState.xy,
        refPosition: refPosition + refVelocityXY.x
      })
    }
  }, [refVelocityRemainFlag, refVelocityXY.x])

  useEffect(() => {
    let newBookshelfLeft = refPosition;
    let newChairLeft = refPosition + bookshelfState.width * 0.99;
    let newFlyerOnWallLeft = refPosition + bookshelfState.width * 1.14;
    let newPosterLeft = refPosition + bookshelfState.width * 1.48;
    let newPhotoOnWallLeft = refPosition + bookshelfState.width * 2.33;
    let newCatLeft = refPosition + bookshelfState.width * 1.45;
    let newChestLeft = refPosition + bookshelfState.width * 2.00;
    let newMirrorLeft = refPosition + bookshelfState.width * 2.87;
    let newEmptyStandLeft = refPosition + bookshelfState.width * 2.87;
    let newSlipperLeft = refPosition + bookshelfState.width * 3.59;
    let newCorporateLogo1Left = refPosition + bookshelfState.width * 3.38;
    let newCorporateLogo2Left = refPosition + bookshelfState.width * 3.74;
    let newCorporateLogo3Left = refPosition + bookshelfState.width * 3.44;
    let newPlantLeft = refPosition + bookshelfState.width * 4.207;

    // 部屋をループをさせる
    newBookshelfLeft = convertToRange(newBookshelfLeft, leftPositionThX, rightPositionThX)
    newChairLeft = convertToRange(newChairLeft, leftPositionThX, rightPositionThX)
    newFlyerOnWallLeft = convertToRange(newFlyerOnWallLeft, leftPositionThX, rightPositionThX)
    newPosterLeft = convertToRange(newPosterLeft, leftPositionThX, rightPositionThX)
    newPhotoOnWallLeft = convertToRange(newPhotoOnWallLeft, leftPositionThX, rightPositionThX)
    newCatLeft = convertToRange(newCatLeft, leftPositionThX, rightPositionThX)
    newChestLeft = convertToRange(newChestLeft, leftPositionThX, rightPositionThX)
    newMirrorLeft = convertToRange(newMirrorLeft, leftPositionThX, rightPositionThX)
    newEmptyStandLeft = convertToRange(newEmptyStandLeft, leftPositionThX, rightPositionThX)
    newSlipperLeft = convertToRange(newSlipperLeft, leftPositionThX, rightPositionThX)
    newCorporateLogo1Left = convertToRange(newCorporateLogo1Left, leftPositionThX, rightPositionThX)
    newCorporateLogo2Left = convertToRange(newCorporateLogo2Left, leftPositionThX, rightPositionThX)
    newCorporateLogo3Left = convertToRange(newCorporateLogo3Left, leftPositionThX, rightPositionThX)
    newPlantLeft = convertToRange(newPlantLeft, leftPositionThX, rightPositionThX)

    setBookshelfLeft(newBookshelfLeft)
    setChairLeft(newChairLeft)
    setFlyerOnWallLeft(newFlyerOnWallLeft)
    setPosterLeft(newPosterLeft)
    setPhotoOnWallLeft(newPhotoOnWallLeft)
    setCatLeft(newCatLeft)
    setChestLeft(newChestLeft)
    setMirrorLeft(newMirrorLeft)
    setEmptyStandLeft(newEmptyStandLeft)
    setSlipperLeft(newSlipperLeft)
    setCorporateLogo1Left(newCorporateLogo1Left)
    setCorporateLogo2Left(newCorporateLogo2Left)
    setCorporateLogo3Left(newCorporateLogo3Left)
    setPlantLeft(newPlantLeft)
  }, [refPosition, height])


  useEffect(() => {
    if (roomElem) {
      roomElem.addEventListener("wheel", onWheel, { passive: false });
      return (() => {
        roomElem.removeEventListener("wheel", onWheel);
      });
    } else {
      window.addEventListener("wheel", onWheel, { passive: false });
      return (() => {
        window.removeEventListener("wheel", onWheel);
      });
    }
  });

  useEffect(() => {
    if (showPopupBase) {
      setRefVelocityXY({ x: 0, y: 0 })
      setRefVelocityRemainFlag(false)
    } else {
      setRefVelocityXY({ x: 0, y: 0 })
    }
  }, [showPopupBase])

  return (
    <>
      <div
        id="room"
        style={{ width: width, height: height, position: "fixed", cursor: "none" }}
        onTouchStart={(e) => { if (isTouchDevice) onTouchStart(e) }}
        onTouchEnd={(e) => { if (isTouchDevice) { onTouchEnd(e) } }}
        onTouchMove={(e) => { if (isTouchDevice) { onTouchMove(e) } }}
        onMouseDown={(e) => { if (!isTouchDevice) onMouseDown(e) }}
        onMouseUp={(e) => { if (!isTouchDevice) { onMouseUp(e) } }}
        onMouseMove={(e) => { if (!isTouchDevice) { onMouseMove(e) } }}
        onMouseLeave={(e) => onMouseLeave(e)}
      >
        <>
          {/* Furniture */}
          <Bookshelf
            left={bookshelfLeft}
            bottom={bookshelfState.bottom + (1 - furnitureFallRate**0.75) * height * 1}
            height={bookshelfHeight}
            width={bookshelfState.width}
            opacityRate={opacityRate**opacityRateExponentValue}
          />
          <Chair
            left={chairLeft}
            bottom={chairState.bottom + (1 - furnitureFallRate**0.6) * height}
            height={chairHeight}
            width={chairState.width}
            opacityRate={opacityRate**opacityRateExponentValue}
          />
          {/* <CatAnimation
              left={catLeft}
              bottom={chairState.seatBottom}
              height={height*0.07}
              opacityRate={opacityRate**opacityRateExponentValue}
            /> */}
          <Chest
            left={chestLeft}
            bottom={chestState.bottom + (1 - furnitureFallRate**0.9) * height}
            height={chestHeight}
            width={chestState.width}
            opacityRate={opacityRate**opacityRateExponentValue}
          />
          <EmptyStand
            left={emptyStandLeft}
            bottom={emptyStandState.bottom + (1 - furnitureFallRate**0.7) * height}
            height={emptyStandHeight}
            width={emptyStandState.width}
            opacityRate={opacityRate**opacityRateExponentValue}
          />
          <Slipper
            left={slipperLeft}
            bottom={slipperState.bottom + (1 - furnitureFallRate**0.5) * height}
            height={slipperHeight}
            width={slipperState.width}
            opacityRate={opacityRate**opacityRateExponentValue}
          />
          {/* Portfolio */}
          <ObjectBase
            left={plantLeft}
            bottom={plantState.bottom + (1 - furnitureFallRate**0.6) * height}
            height={plantHeight}
            width={plantState.width}
            maxZoomRate={1.04}
            firstViewFlag={firstViewFlag}
            setFirstViewFlag={setFirstViewFlag}
            opacityRate={opacityRate**opacityRateExponentValue}
            currentPortfolioType={currentPortfolioType}
            showPopupBase={showPopupBase}
            setShowPopupBase={setShowPopupBase}
            portfolioType="about me"
            setPortfolioType={setPortfolioType}
          >
            <Plant />
          </ObjectBase>
          <ObjectBase
            left={flyerOnWallLeft}
            top={height * 0.12 + posterHeight - flyerOnWallHeight - (1 - portfolioFallRate**0.65) * height}
            height={flyerOnWallHeight}
            width={flyerOnWallHeight * 188.29526 / 188.29527}
            firstViewFlag={firstViewFlag}
            setFirstViewFlag={setFirstViewFlag}
            opacityRate={opacityRate**opacityRateExponentValue}
            currentPortfolioType={currentPortfolioType}
            showPopupBase={showPopupBase}
            setShowPopupBase={setShowPopupBase}
            portfolioType="flyer on wall"
            setPortfolioType={setPortfolioType}
          >
            <FlyerOnWall />
          </ObjectBase>
          <ObjectBase
            left={posterLeft}
            top={height * 0.12 - (1 - portfolioFallRate**0.9) * height}
            height={posterHeight}
            width={posterHeight * 152.30096 / 215.41862}
            maxZoomRate={1.07}
            firstViewFlag={firstViewFlag}
            setFirstViewFlag={setFirstViewFlag}
            opacityRate={opacityRate**opacityRateExponentValue}
            currentPortfolioType={currentPortfolioType}
            showPopupBase={showPopupBase}
            setShowPopupBase={setShowPopupBase}
            portfolioType="poster"
            setPortfolioType={setPortfolioType}
          >
            <Poster />
          </ObjectBase>
          <ObjectBase
            left={photoOnWallLeft}
            top={height * 0.12 + posterHeight - photoOnWallHeight - (1 - portfolioFallRate**0.7) * height}
            height={photoOnWallHeight}
            width={photoOnWallHeight * 156.86646 / 156.86646}
            firstViewFlag={firstViewFlag}
            setFirstViewFlag={setFirstViewFlag}
            opacityRate={opacityRate**opacityRateExponentValue}
            currentPortfolioType={currentPortfolioType}
            showPopupBase={showPopupBase}
            setShowPopupBase={setShowPopupBase}
            portfolioType="photo on wall"
            setPortfolioType={setPortfolioType}
          >
            <PhotoOnWall />
          </ObjectBase>
          <ObjectBase
            left={chestLeft}
            bottom={chestState.chestTopBottom + (1 - portfolioFallRate**1) * height}
            height={chestHeight * 0.5}
            width={chestHeight * 0.5 * 69.57711 / 75.020065}
            firstViewFlag={firstViewFlag}
            setFirstViewFlag={setFirstViewFlag}
            opacityRate={opacityRate**opacityRateExponentValue}
            currentPortfolioType={currentPortfolioType}
            showPopupBase={showPopupBase}
            setShowPopupBase={setShowPopupBase}
            portfolioType="pendentif"
            setPortfolioType={setPortfolioType}
          >
            <Pendentif />
          </ObjectBase>
          <ObjectBase
            left={bookshelfLeft - bookshelfState.width * 0.249}
            bottom={bookshelfState.shelf2Bottom + (1 - portfolioFallRate**0.75) * height}
            height={bookshelfHeight * 0.265}
            width={bookshelfHeight * 0.265 * 188.29526 / 188.29527}
            firstViewFlag={firstViewFlag}
            setFirstViewFlag={setFirstViewFlag}
            opacityRate={opacityRate**opacityRateExponentValue}
            currentPortfolioType={currentPortfolioType}
            showPopupBase={showPopupBase}
            setShowPopupBase={setShowPopupBase}
            portfolioType="playlist"
            setPortfolioType={setPortfolioType}
          >
            <Playlist />
          </ObjectBase>
          <ObjectBase
            left={bookshelfLeft + bookshelfState.width * 0.023}
            bottom={bookshelfState.shelf2Bottom + (1 - portfolioFallRate**0.6) * height}
            height={bookshelfHeight * 0.265}
            width={bookshelfHeight * 0.265 * 69.676888 / 114.47317}
            firstViewFlag={firstViewFlag}
            setFirstViewFlag={setFirstViewFlag}
            opacityRate={opacityRate**opacityRateExponentValue}
            currentPortfolioType={currentPortfolioType}
            showPopupBase={showPopupBase}
            setShowPopupBase={setShowPopupBase}
            portfolioType="invitation"
            setPortfolioType={setPortfolioType}
          >
            <Invitation />
          </ObjectBase>
          <ObjectBase
            left={bookshelfLeft + bookshelfState.width * 0.25}
            bottom={bookshelfState.shelf2Bottom + (1 - portfolioFallRate**0.69) * height}
            height={bookshelfHeight * 0.265}
            width={bookshelfHeight * 0.265 * 125.8516 / 183.12573}
            firstViewFlag={firstViewFlag}
            setFirstViewFlag={setFirstViewFlag}
            opacityRate={opacityRate**opacityRateExponentValue}
            currentPortfolioType={currentPortfolioType}
            showPopupBase={showPopupBase}
            setShowPopupBase={setShowPopupBase}
            portfolioType="leaflet"
            setPortfolioType={setPortfolioType}
          >
            <Leaflet />
          </ObjectBase>
          <ObjectBase
            left={bookshelfLeft - bookshelfState.width * 0.2}
            bottom={bookshelfState.shelf1Bottom + (1 - portfolioFallRate**0.65) * height}
            height={bookshelfHeight * 0.25}
            width={bookshelfHeight * 0.25 * 155.08273 / 105.32652}
            firstViewFlag={firstViewFlag}
            setFirstViewFlag={setFirstViewFlag}
            opacityRate={opacityRate**opacityRateExponentValue}
            currentPortfolioType={currentPortfolioType}
            showPopupBase={showPopupBase}
            setShowPopupBase={setShowPopupBase}
            portfolioType="cassette tape"
            setPortfolioType={setPortfolioType}
          >
            <CassetteTape />
          </ObjectBase>
          <ObjectBase
            left={bookshelfLeft + bookshelfState.width * 0.09}
            bottom={bookshelfState.shelf1Bottom + (1 - portfolioFallRate**0.6) * height}
            height={bookshelfHeight * 0.225}
            width={bookshelfHeight * 0.225 * 60.57 / 110.58}
            firstViewFlag={firstViewFlag}
            setFirstViewFlag={setFirstViewFlag}
            opacityRate={opacityRate**opacityRateExponentValue}
            currentPortfolioType={currentPortfolioType}
            showPopupBase={showPopupBase}
            setShowPopupBase={setShowPopupBase}
            portfolioType="flyer in bookshelf"
            setPortfolioType={setPortfolioType}
          >
            <FlyerInBookshelf />
          </ObjectBase>
          <ObjectBase
            left={bookshelfLeft + bookshelfState.width * 0.27}
            bottom={bookshelfState.shelfTopBottom + (1 - portfolioFallRate**0.85) * height}
            height={bookshelfHeight * 0.125}
            width={bookshelfHeight * 0.125 * 147.55896 / 48.829399}
            firstViewFlag={firstViewFlag}
            setFirstViewFlag={setFirstViewFlag}
            opacityRate={opacityRate**opacityRateExponentValue}
            currentPortfolioType={currentPortfolioType}
            showPopupBase={showPopupBase}
            setShowPopupBase={setShowPopupBase}
            portfolioType="dish"
            setPortfolioType={setPortfolioType}
          >
            <Dish />
          </ObjectBase>
          <ObjectBase
            left={mirrorLeft}
            top={mirrorState.top - (1 - furnitureFallRate**0.5) * height}
            height={mirrorHeight}
            width={mirrorState.width}
            maxZoomRate={1.05}
            firstViewFlag={firstViewFlag}
            setFirstViewFlag={setFirstViewFlag}
            opacityRate={opacityRate**opacityRateExponentValue}
            currentPortfolioType={currentPortfolioType}
            showPopupBase={showPopupBase}
            setShowPopupBase={setShowPopupBase}
            portfolioType="mirror"
            setPortfolioType={setPortfolioType}
          >
            <Mirror />
          </ObjectBase>
          <ObjectBase
            left={corporateLogo1Left}
            top={height * 0.11 - (1 - portfolioFallRate**1.0) * height}
            height={corporateLogo1Height}
            width={corporateLogo1Height * 44.764854 / 53.117748}
            firstViewFlag={firstViewFlag}
            setFirstViewFlag={setFirstViewFlag}
            opacityRate={opacityRate**opacityRateExponentValue}
            currentPortfolioType={currentPortfolioType}
            showPopupBase={showPopupBase}
            setShowPopupBase={setShowPopupBase}
            portfolioType="async studio"
            setPortfolioType={setPortfolioType}
          >
            <CorporateLogo1 />
          </ObjectBase>
          <ObjectBase
            left={corporateLogo2Left}
            top={height * 0.18 - (1 - portfolioFallRate**0.9) * height}
            height={corporateLogo2Height}
            width={corporateLogo2Height * 37.218918 / 50.429585}
            firstViewFlag={firstViewFlag}
            setFirstViewFlag={setFirstViewFlag}
            opacityRate={opacityRate**opacityRateExponentValue}
            currentPortfolioType={currentPortfolioType}
            showPopupBase={showPopupBase}
            setShowPopupBase={setShowPopupBase}
            portfolioType="necotto"
            setPortfolioType={setPortfolioType}
          >
            <CorporateLogo2 />
          </ObjectBase>
          <ObjectBase
            left={corporateLogo3Left}
            top={height * 0.375 - (1 - portfolioFallRate**0.7) * height}
            height={corporateLogo3Height}
            width={corporateLogo3Height * 56.813976 / 40.867653}
            firstViewFlag={firstViewFlag}
            setFirstViewFlag={setFirstViewFlag}
            opacityRate={opacityRate**opacityRateExponentValue}
            currentPortfolioType={currentPortfolioType}
            showPopupBase={showPopupBase}
            setShowPopupBase={setShowPopupBase}
            portfolioType="vok"
            setPortfolioType={setPortfolioType}
          >
            <CorporateLogo3 />
          </ObjectBase>
        </>
      </div>
    </>
  )
}

export default RoomView