import React, { useState, useEffect, ReactNode } from 'react'
import Modal from 'react-modal';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useWindowDimensions } from '../../../utils/windowDimensions'
import VerticalDivider from '../../VerticalDivider';
import ReadMore from '../../ReadMore';
import { map } from '../../../utils/math';
import HorizontalDivider from '../../HorizontalDivider';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Mousewheel } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/mousewheel';
import './CustomSwiper.css'
import './CustomModal.css'
import { extractFileName, hasJapanese, spImgPath } from '../../../utils/string';
import { PortfolioDetail } from '../../../model/Portfolio';
import { XY } from '../../../model/XY';
import CloseButton from '../../CloseButton';

type Props = {
  children: ReactNode;
  fontResizeRate: number;
}

export const PopupBody: React.FC<Props> = ({ children, fontResizeRate }) => {
  return (
    <div className='popup-body' style={{
      fontSize: `${12 * 17.5 / 16 * fontResizeRate}pt`,
      letterSpacing: `${0.0 * fontResizeRate}pt`,
      lineHeight: `${25 * fontResizeRate}pt`,
      marginBottom: `${45 * fontResizeRate}px`,
    }}>
      {children}
    </div>
  )
}

export const PopupBodySubHeadline: React.FC<Props> = ({ children, fontResizeRate }) => {
  return (
    <div className='popup-body-subheadline' style={{
      fontSize: `${17.5 * fontResizeRate}pt`,
      letterSpacing: `${1 * fontResizeRate}pt`,
      lineHeight: `${28 * fontResizeRate}pt`,
      marginBottom: `${14 * fontResizeRate}px`,
    }}>
      {children}
    </div>
  )
}

const modalBackgroundColor = '#e9e7dc'

export type ModalMove = "" | "InFromR" | "InFromL" | "OutToR" | "OutToL";

type ModalProps = {
  children: (fontResizeRate: number) => ReactNode;
  modalMove: ModalMove;
  portfolioDetail: PortfolioDetail;
  showPopupBase: boolean;
  setShowPopupBase: React.Dispatch<React.SetStateAction<boolean>>;
  headerHeight: number;
  setMouseXY: React.Dispatch<React.SetStateAction<XY>>;
  displayDesignConcept?: boolean;
}

const PopupBase: React.FC<ModalProps> = ({ children, modalMove, portfolioDetail, showPopupBase, setShowPopupBase, headerHeight, setMouseXY, displayDesignConcept = true }) => {
  const { width, height, verticalFlag, verticalDevice } = useWindowDimensions()
  const [readMoreRotate, setReadMoreRotate] = useState(0);
  const [readMoreMoveStart, setReadMoreMoveStart] = useState(false);
  const [readMoreArrowPositionYRate, setReadMoreArrowPositionYRate] = useState(0.5);
  const [readMoreArrowPositionYDirection, setReadMoreArrowPositionYDirection] = useState(true);
  const [mouseHoverOnCloseButton, setMouseHoverOnCloseButton] = useState(false);
  const modalHeightPC = Math.max(height - headerHeight * 2, 300)
  let fontResizeRate = (modalHeightPC / 800) ** 0.7
  if (!verticalFlag && verticalDevice) {
    // タブレットで縦長表示する場合には文字サイズ大きくする
    fontResizeRate *= (width / 650) ** 0.7
  }
  const phoneModalHeight = Math.min(height * 0.5 + width * 0.6 / 0.9 + height * 0.005, height)
  const duration = modalMove === "" ? 450 : 900
  const modalStyle: Modal.Styles = {
    overlay: {
      position: "fixed",
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      backgroundColor: "rgba(255,255,255,0)",
    },
    content: verticalDevice ? {
      position: "fixed",
      left: '0',
      right: '0',
      top: height - phoneModalHeight,
      bottom: '0',
      margin: 'auto',
      padding: '0px',
      width: '95vw',
      height: phoneModalHeight,
      color: '#222222',
      backgroundColor: modalBackgroundColor,
      backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/anjumori-f0a76.appspot.com/o/am_stone01.jpg?alt=media)",
      borderRadius: '0px',
      borderColor: "transparent",
      overflowY: "hidden",
      animation: `rotateAnimation${modalMove}${verticalFlag ? "" : "PC"} ${duration}ms ease-in-out`,
      transformOrigin: `center -${Math.max(height, width) * 4}px`,
      opacity: modalMove.includes("Out") ? 0 : 1,
    } : {
      position: "fixed",
      left: '0',
      right: '0',
      top: '0',
      bottom: '0',
      margin: 'auto',
      padding: '0px',
      width: '90vw',
      height: modalHeightPC,
      color: '#222222',
      backgroundColor: modalBackgroundColor,
      backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/anjumori-f0a76.appspot.com/o/am_stone01.jpg?alt=media)",
      borderRadius: '0px',
      borderColor: "transparent",
      overflowY: "hidden",
      animation: `rotateAnimation${modalMove}${verticalFlag ? "" : "PC"} ${duration}ms ease-in-out`,
      transformOrigin: `center -${Math.max(height, width) * 4}px`,
      zIndex: 10,
      opacity: modalMove.includes("Out") ? 0 : 1,
    }
  };
  const handleClose = () => {
    setShowPopupBase(false);
  }
  const applyWbr = (title: string) => {
    const appliedTitle = title.split(/(<wbr>)/).map((item, index) => {
      return (
        <React.Fragment key={index}>
          {item.match(/<wbr>/) ? <wbr /> : item}
        </React.Fragment>
      );
    });
    return <div>{appliedTitle}</div>;
  }
  useEffect(() => {
    if (!modalMove.includes("Out")) {
      setTimeout(() => {
        setReadMoreMoveStart(true)
      }, duration);
    }
  }, [])
  useEffect(() => {
    if (readMoreMoveStart) {
      setTimeout(() => {
        let newReadMoreRotate = readMoreRotate + 1
        if (newReadMoreRotate >= 360) { newReadMoreRotate = 0 }
        setReadMoreRotate(newReadMoreRotate);
      }, 1000 / 60);
    }
  }, [readMoreRotate, readMoreMoveStart])
  useEffect(() => {
    if (readMoreMoveStart) {
      setTimeout(() => {
        if (readMoreArrowPositionYDirection) {
          let newReadMoreArrowPositionYRate = readMoreArrowPositionYRate + (map(Math.abs(readMoreArrowPositionYRate - 0.5), 0, 0.5, 0.025, Math.min(0.005, 1 - readMoreArrowPositionYRate)))
          if (1 - newReadMoreArrowPositionYRate < 0.001) {
            setReadMoreArrowPositionYDirection(false)
          }
          setReadMoreArrowPositionYRate(newReadMoreArrowPositionYRate);
        } else {
          let newReadMoreArrowPositionYRate = readMoreArrowPositionYRate - (map(Math.abs(readMoreArrowPositionYRate - 0.5), 0, 0.5, 0.025, Math.min(0.005, readMoreArrowPositionYRate)))
          if (newReadMoreArrowPositionYRate < 0.001) {
            setReadMoreArrowPositionYDirection(true)
          }
          setReadMoreArrowPositionYRate(newReadMoreArrowPositionYRate);
        }
      }, 1000 / 60);
    }
  }, [readMoreArrowPositionYRate, readMoreArrowPositionYDirection, readMoreMoveStart])
  return (
    <>
      <Modal
        isOpen={showPopupBase}
        onRequestClose={() => { handleClose() }}
        style={modalStyle}
        ariaHideApp={false}
        closeTimeoutMS={100}
      >
        <div
          style={{ position: 'absolute', zIndex: 100, right: "0px", width: "auto", height: verticalFlag ? "17px" : "22px" }}
          onMouseEnter={() => setMouseHoverOnCloseButton(true)}
          onMouseLeave={() => setMouseHoverOnCloseButton(false)}
        >
          <CloseButton
            padding={verticalFlag ? "7.5px" : "15px"}
            backgroundColor={mouseHoverOnCloseButton ? `rgba(${233 * 0.6}, ${231 * 0.6}, ${220 * 0.6}, 0.25)` : "rgba(233, 231, 220, 0.8)"}
            handleClose={handleClose}
          />
        </div>
        <Box sx={{ width: '100%', height: "100%" }}>
          {verticalDevice ? (
            <>
              <div style={{ backgroundColor: "#e9e7dc", position: "absolute", width: '100%', height: phoneModalHeight - height * 0.5, zIndex: 1 }} />
              <Swiper
                modules={[Pagination, Mousewheel]}
                pagination={{ type: "bullets", clickable: true }}
                mousewheel={true}
                style={{ position: "absolute", width: '100%', height: phoneModalHeight - height * 0.5, textAlign: "center", zIndex: 2 }}
              >
                {portfolioDetail.imgPaths.map((imgPath, ind) => {
                  return (
                    <SwiperSlide key={ind}>
                      <img style={{ height: "auto", width: "100%" }} src={spImgPath(imgPath, verticalFlag)} alt={extractFileName(imgPath)} />
                    </SwiperSlide>
                  )
                })}
              </Swiper>
              <Grid container justifyContent='center' style={{ width: '100%', height: "100%", overflowY: 'scroll' }}>
                <div style={{ width: '100%', height: phoneModalHeight - height * 0.5 }} />
                <div style={{ width: '100%', height: "100%", paddingLeft: "7%", paddingRight: "7%", textAlign: "left", fontSize: "40pt" }}>
                  {displayDesignConcept ? (
                    <>
                      <div style={{ height: "3%" }} />
                      <div className="popup-title" style={{ fontSize: `${28 * fontResizeRate * 0.75 * (hasJapanese(portfolioDetail.title) ? 0.9 : 1)}pt`, lineHeight: `${45 * fontResizeRate * 0.9}px`, letterSpacing: `${0 * fontResizeRate * 0.17}pt`, paddingBottom: "1%", textAlign: "center" }}>
                        {applyWbr(portfolioDetail.title)}
                      </div>
                      <div className="popup-about" style={{ fontSize: `${17 * fontResizeRate * 0.75}pt`, lineHeight: `${30 * fontResizeRate * 0.75}px`, textAlign: "center", paddingBottom: "1%" }}>
                        {portfolioDetail.about}
                      </div>
                      <div className="popup-year" style={{ fontSize: `${15 * fontResizeRate * 0.75}pt`, lineHeight: `${30 * fontResizeRate * 0.75}px`, textAlign: "center" }}>
                        {portfolioDetail.year}
                      </div>
                      <div style={{ height: "3%" }} />
                      <div className='popup-about' style={{ fontSize: `${15 * fontResizeRate * 0.75}pt`, textAlign: "center" }}>
                        {"Design Concept"}
                      </div>
                      <div style={{ height: "1%" }} />
                      <Grid container justifyContent="space-around" alignItems="flex-end" style={{ textAlign: "center" }}>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={6}>
                          <div className='popup-headline' style={{ fontSize: `${20 * fontResizeRate * 0.8}pt`, lineHeight: `${45 * fontResizeRate * 0.7}px`, textAlign: "left", margin: "0 auto", paddingTop: "7%" }}>
                            {portfolioDetail.headline}
                          </div>
                        </Grid>
                        <Grid item xs={3} alignContent="flex-end" style={{ height: 90 * fontResizeRate * 0.75 * 1.2, textAlign: "end" }}>
                          <ReadMore height={90 * fontResizeRate * 0.75 * 1.2} rotate={readMoreRotate} arrowPositionYRate={readMoreArrowPositionYRate} />
                        </Grid>
                      </Grid>
                    </>
                  ) : <></>}
                  <div style={{ height: "5%" }} />
                  {children(fontResizeRate * 1)}
                  <div style={{ height: "3%" }} />
                </div>
              </Grid>
            </>
          ) : (
            <>
              <Grid container justifyContent='center' style={{ width: '100%', height: "100%" }}>
                <Grid item xs={7.5} style={{ width: '100%', height: "100%", overflowY: 'hidden' }}>
                  <div style={{ position: "absolute", height: modalHeightPC * 0.15, width: `${100 * 7.5 / 12}%`, display: "table", zIndex: 10 }}>
                    <div style={{ verticalAlign: "middle", display: "table-cell" }}>
                      <div className="popup-title" style={{ fontSize: `${28 * fontResizeRate * (hasJapanese(portfolioDetail.title) ? 0.9 : 1)}pt`, lineHeight: `${0.9 * 40 * fontResizeRate}px`, letterSpacing: `${0 * fontResizeRate}pt`, paddingLeft: "4%", paddingTop: "2%", paddingBottom: modalHeightPC * 0.015 }}>
                        {applyWbr(portfolioDetail.title)}
                      </div>
                      <div className="popup-about" style={{ fontSize: `${17 * fontResizeRate}pt`, lineHeight: `${0.9 * 25 * fontResizeRate}px`, paddingLeft: "4%", paddingBottom: modalHeightPC * 0.01 }}>
                        {portfolioDetail.about}
                      </div>
                      <div className="popup-year" style={{ fontSize: `${15 * fontResizeRate}pt`, lineHeight: `${0.9 * 20 * fontResizeRate}px`, paddingLeft: "4%", paddingBottom: modalHeightPC * 0.005 }}>
                        {portfolioDetail.year}
                      </div>
                    </div>
                  </div>
                  <div style={{ height: modalHeightPC * 0.85, width: '100%', marginTop: modalHeightPC * 0.15, overflowY: 'hidden', textAlign: "center", display: "table", zIndex: 0 }}>
                    <Swiper
                      modules={[Pagination, Mousewheel]}
                      pagination={{ type: "bullets", clickable: true }}
                      mousewheel={true}
                      style={{ position: "absolute", height: modalHeightPC * 0.85, width: `${(100 * 7.5 / 12)}%`, verticalAlign: "middle", display: "table-cell", textAlign: "center", alignItems: "center" }}
                      onTouchMove={(e: any) => {
                        setMouseXY({
                          x: e.touches.currentX,
                          y: e.touches.currentY,
                        })
                      }}
                    >
                      {portfolioDetail.imgPaths.map((imgPath, ind) => {
                        return (
                          <SwiperSlide key={ind} style={{ height: modalHeightPC * 0.82, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img style={{ height: "auto", width: `${92 * Math.min(modalHeightPC * 0.8 / ((width * 7.5 / 12) * 0.92 * 0.6), 1)}%` }} src={spImgPath(imgPath, verticalFlag)} alt={extractFileName(imgPath)} />
                          </SwiperSlide>
                        )
                      })}
                    </Swiper>
                  </div>
                </Grid>
                <Grid item xs={4.5} style={{ width: '100%', overflowY: 'scroll', height: "100%" }}>
                  {displayDesignConcept ? (
                    <Grid container direction="row" alignContent="space-evenly" style={{ width: '100%', height: "100%", paddingLeft: "10%", paddingRight: "10%", textAlign: "center" }}>
                      <Grid item style={{ height: "auto", width: "100%", display: "table" }}>
                        <div className='popup-about' style={{ fontSize: `${16 * fontResizeRate}pt`, verticalAlign: "middle", display: "table-cell" }}>
                          {"Design Concept"}
                        </div>
                      </Grid>
                      <Grid item style={{ width: "100%", display: "table", textAlign: "center" }}>
                        <div style={{ verticalAlign: "middle", display: "table-cell" }}>
                          <div className='popup-headline' style={{ fontSize: `${20 * fontResizeRate}pt`, lineHeight: `${45 * fontResizeRate}px`, textAlign: "left", margin: "0 auto" }}>
                            {portfolioDetail.headline}
                          </div>
                        </div>
                      </Grid>
                      <Grid item style={{ width: "100%", display: "table", textAlign: "center" }} justifyContent="center">
                        <div style={{ verticalAlign: "middle", display: "table-cell" }}>
                          <ReadMore height={90 * fontResizeRate} rotate={readMoreRotate} arrowPositionYRate={readMoreArrowPositionYRate} />
                        </div>
                      </Grid>
                    </Grid>
                  ) : <></>}
                  {/* 説明部分 */}
                  <div id="popup_description" style={{ paddingTop: modalHeightPC * 0.07, paddingBottom: modalHeightPC * 0.07, paddingLeft: "10%", paddingRight: "10%" }}>
                    <div style={{ minHeight: modalHeightPC * 0.86 }}>
                      {children(fontResizeRate * 1)}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
        {verticalDevice ? (
          <div style={{ position: 'absolute', width: width - 90, height: 0, top: phoneModalHeight - height * 0.5 - 13.5, left: 35, zIndex: 100 }}>
            <HorizontalDivider />
          </div>
        ) : (
          <div style={{ position: 'absolute', width: 0, height: "100%", left: "62.5%", top: 0, zIndex: 100 }}>
            <VerticalDivider />
          </div>
        )}
      </Modal>
    </>
  )
}

export default PopupBase